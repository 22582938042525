import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Router from 'next/router';

const WhyHomiiLifeMenuComponent = () => {
    const onLiveYourBestLifeClick = () => {
        Router.push('/live/live-your-best-life').then(() => window.scrollTo(0, 0));
    };

    const onAboutHomiiClick = () => {
        Router.push('/about').then(() => window.scrollTo(0, 0));
    };

    const onOurAppClick = () => {
        Router.push('/live/our-app').then(() => window.scrollTo(0, 0));
    };

    const onYourOfficeClick = () => {
        Router.push('/work/your-office').then(() => window.scrollTo(0, 0));
    };

    const onOwnYourCityClick = () => {
        Router.push('/play/own-your-city').then(() => window.scrollTo(0, 0));
    };

    const onGalleryClick = () => {
        Router.push('/play/gallery').then(() => window.scrollTo(0, 0));
    };

    return (
        <Grid item container md={12} lg={6} spacing={0}>
            <Grid item md>
                <StyledHeader5 marginBottom={15}>#Live</StyledHeader5>
                <List>
                    <ListItem
                        button
                        className="menu-list-item"
                        id="menu-link-about"
                        onClick={onAboutHomiiClick}
                    >
                        <ListItemText primary="About HOMii" className="menu-link" />
                    </ListItem>
                    <ListItem
                        button
                        className="menu-list-item"
                        id="menu-link-welcome-to-your-best-life"
                        onClick={onLiveYourBestLifeClick}
                    >
                        <ListItemText primary="Welcome To Your Best Life" className="menu-link" />
                    </ListItem>
                    <ListItem
                        button
                        className="menu-list-item"
                        id="menu-link-do-life-on-our-app"
                        onClick={onOurAppClick}
                    >
                        <ListItemText primary="Do Life On Our App" className="menu-link" />
                    </ListItem>
                </List>
            </Grid>
            <Grid item md>
                <StyledHeader5 marginBottom={15}>#Work</StyledHeader5>
                <List>
                    <ListItem
                        button
                        className="menu-list-item"
                        id="menu-link-your-homii-office"
                        onClick={onYourOfficeClick}
                    >
                        <ListItemText primary="Your HOMii Office" className="menu-link" />
                    </ListItem>
                </List>
            </Grid>
            <Grid item md>
                <StyledHeader5 marginBottom={15}>#Play</StyledHeader5>
                <List>
                    <ListItem
                        button
                        className="menu-list-item"
                        id="menu-link-own-your-city"
                        onClick={onOwnYourCityClick}
                    >
                        <ListItemText primary="Own Your City" className="menu-link" />
                    </ListItem>
                    <ListItem
                        button
                        className="menu-list-item"
                        id="menu-link-gallery"
                        onClick={onGalleryClick}
                    >
                        <ListItemText primary="Gallery" className="menu-link" />
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

export default WhyHomiiLifeMenuComponent;

const StyledHeader5 = styled.h5`
    margin-bottom: ${(props) => props.marginBottom}px;
`;
