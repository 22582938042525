import networkService from '../network-service/network.service';
import portfolioUrls from './portfolio-service-urls';

function getPortfolioById(portfolioId) {
    const url = portfolioUrls.getPortfolioByIdUrl(portfolioId);
    return networkService.makeGet(url);
}

function getAllPortfolios() {
    const url = portfolioUrls.getAllPortfolioUrl();
    return networkService.makeGet(url);
}

function submitPortfolioInquiry(
    portfolioId,
    roomTypeId,
    firstName,
    lastName,
    email,
    phoneNumber,
    inquiry,
) {
    const url = portfolioUrls.portfolioInquiry();
    const data = { portfolioId, roomTypeId, firstName, lastName, email, phoneNumber, inquiry };
    return networkService.makePost(url, data);
}

function getPortfolioCities(portfolioId) {
    const url = portfolioUrls.getPortfolioCitiesUrl(portfolioId);
    return networkService.makeGet(url);
}

export default {
    getPortfolioById,
    getAllPortfolios,
    submitPortfolioInquiry,
    getPortfolioCities,
};
