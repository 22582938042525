import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'agent';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setAllAgents = new CreateAction(reducerName, 'SET_ALL_AGENTS');
export const setAllAgentsActions = setAllAgents.action;

const setAgentDetails = new CreateAction(reducerName, 'SET_AGENT_DETAILS');
export const setAgentDetailsAction = setAgentDetails.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    allAgents: null,
    agentDetails: null,
};

export default function agentReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setAllAgents.actionType:
            return { ...state, allAgents: action.payload };
        case setAgentDetails.actionType:
            return { ...state, agentDetails: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
