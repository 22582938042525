import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'building';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setBuildings = new CreateAction(reducerName, 'SET_BUILDINGS');
export const setBuildingsAction = setBuildings.action;

const setSelectedBuilding = new CreateAction(reducerName, 'SET_SELECTED_BUILDING');
export const setSelectedBuildingAction = setSelectedBuilding.action;

const setCurrentBuilding = new CreateAction(reducerName, 'SET_CURRENT_BUILDING');
export const setCurrentBuildingAction = setCurrentBuilding.action;

const setBuildingLocations = new CreateAction(reducerName, 'SET_BUILDING_LOCATIONS');
export const setBuildingLocationsAction = setBuildingLocations.action;

const setBuildingContentCMS = new CreateAction(reducerName, 'SET_BUILDING_CONTENT_CMS');
export const setBuildingContentCMSAction = setBuildingContentCMS.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    buildings: {},
    selectedBuilding: null,
    currentBuilding: null,
    buildingLocations: [],
    buildingContentCMS: null,
};

export default function buildingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setBuildings.actionType:
            return { ...state, buildings: action.payload };
        case setSelectedBuilding.actionType:
            return { ...state, selectedBuilding: action.payload };
        case setCurrentBuilding.actionType:
            return { ...state, currentBuilding: action.payload };
        case setBuildingLocations.actionType:
            return { ...state, buildingLocations: action.payload };
        case setBuildingContentCMS.actionType:
            return { ...state, buildingContentCMS: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
