import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import useResponsive from '../../helpers/responsive';

import Image from './image.component';

const iff = (condition, then, otherwise) => {
    return condition ? then : otherwise;
};

const Hover = (props) => {
    const { isMobile, isSmallTablet, isTablet, isLargeTablet } = useResponsive();
    const { imgSrc, imgAlt } = props;

    const renderHoverEffect = () => {
        return (
            <CutContainer isMobile={isMobile} isSmallTablet={isSmallTablet} isTablet={isTablet}>
                <BackgroundDiv
                    isMobile={isMobile}
                    isTablet={isTablet}
                    isSmallTablet={isSmallTablet}
                    isLargeTablet={isLargeTablet}
                />
                <SliceContainer
                    isMobile={isMobile}
                    isSmallTablet={isSmallTablet}
                    isTablet={isTablet}
                >
                    <StyledImage src={imgSrc} alt={imgAlt} isOldImage />
                </SliceContainer>
            </CutContainer>
        );
    };

    return (
        <HoverContainer
            isMobile={isMobile}
            isTablet={isTablet}
            isSmallTablet={isSmallTablet}
            isLargeTablet={isLargeTablet}
        >
            {renderHoverEffect()}
        </HoverContainer>
    );
};

export default Hover;

Hover.propTypes = {
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
};

const Animation = (y) => keyframes`
    0% { transform: translateY(-${y}%)} 
    100% { transform: translateY(${y}%)} 
`;

const HoverContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: ${(props) =>
        props.isMobile || props.isSmallTablet || props.isTablet || props.isLargeTablet
            ? 'hidden'
            : null};
`;

const BackgroundDiv = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    border-radius: 25px;
    background-color: #f8f8f8;
    height: 77%;
    width: ${(props) =>
        iff(
            props.isMobile,
            '90%',
            iff(props.isSmallTablet || props.isTablet || props.isLargeTablet, '60%', '90%'),
        )};
    top: ${(props) => (props.isMobile ? '0%' : '0%')};
    left: ${(props) => (props.isMobile ? '0%' : '0%')};
`;

const CutContainer = styled.div`
    height: ${(props) =>
        iff(
            props.isMobile,
            '480px',
            iff(props.isSmallTablet, '600px', iff(props.isTablet, '750px', '792px')),
        )};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
`;

const SliceContainer = styled.div`
    position: absolute;
    z-index: 3;
    animation-name: ${(props) =>
        Animation(
            iff(props.isMobile, '5', iff(props.isSmallTablet, '4', iff(props.isTablet, '4', '6'))),
        )};
    animation-delay: 0s;
    animation-timing-function: cubic-bezier(0.43, 0.05, 0.6, 0.99);
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: none;
    animation-play-state: running;
    height: ${(props) => (props.isMobile || props.isSmallTablet ? '95%' : '100%')};
    width: auto;
`;

const StyledImage = styled(Image)`
    position: relative;
    width: auto;
    height: 100%;
    top: 0%;
    left: 0%;
`;
