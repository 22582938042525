/* eslint-disable no-shadow */
import _ from 'lodash';
import getConfig from 'next/config';

import mediaUrls from './media.service-url';

const { publicRuntimeConfig } = getConfig();

const DOCUMENT_TYPES = Object.freeze({
    Unknown: 0,
    Identification: 1,
    FacePhoto: 2,
    Image: 3,
    Floorplan: 4,
    FeaturedAmenity: 5,
});

function getImageUri(document, entityId, entityType, thumbnailOptions) {
    const documentId = _.get(document, 'documentId');
    const thumbnailScale = _.get(thumbnailOptions, 'scale');
    const thumbnailQuality = _.get(thumbnailOptions, 'quality');

    switch (entityType) {
        case 'city':
            if (!thumbnailOptions) {
                return `${mediaUrls.getCityDocumentUrl(entityId, documentId)}`;
            }
            return `${mediaUrls.getCityDocumentThumbnailUrl(
                thumbnailScale,
                thumbnailQuality,
                entityId,
                documentId,
            )}`;
        case 'building':
            if (!thumbnailOptions) {
                return `${mediaUrls.getBuildingDocumentUrl(entityId, documentId)}`;
            }
            return `${mediaUrls.getBuildingDocumentThumbnailUrl(
                thumbnailScale,
                thumbnailQuality,
                entityId,
                documentId,
            )}`;
        case 'roomType':
            if (!thumbnailOptions) {
                return `${mediaUrls.getRoomTypeDocumentUrl(entityId, documentId)}`;
            }
            return `${mediaUrls.getRoomTypeDocumentThumbnailUrl(
                thumbnailScale,
                thumbnailQuality,
                entityId,
                documentId,
            )}`;
        case 'portfolio':
            if (!thumbnailOptions) {
                return `${mediaUrls.getPortfolioDocumentUrl(entityId, documentId)}`;
            }
            return `${mediaUrls.getPortfolioDocumentThumbnailUrl(
                thumbnailScale,
                thumbnailQuality,
                entityId,
                documentId,
            )}`;
        case 'agent':
            if (!thumbnailOptions) {
                return `${mediaUrls.getAgentDocumentUrl(entityId, documentId)}`;
            }
            return `${mediaUrls.getAgentDocumentThumbnailUrl(
                thumbnailScale,
                thumbnailQuality,
                entityId,
                documentId,
            )}`;
        default:
            return null;
    }
}

function generateEntityImageSources(documents, entityId, entityType) {
    let imageSources = _.map(documents, (document) => {
        if (_.get(document, 'type') === DOCUMENT_TYPES.Image) {
            return `/api/image?url=${encodeURIComponent(
                getImageUri(document, entityId, entityType),
            )}`;
        }
        return null;
    });

    imageSources = _.compact(imageSources);

    let floorplanSources = _.map(documents, (document) => {
        if (_.get(document, 'type') === DOCUMENT_TYPES.Floorplan) {
            return `/api/image?url=${encodeURIComponent(
                getImageUri(document, entityId, entityType),
            )}`;
        }
        return null;
    });
    floorplanSources = _.compact(floorplanSources);

    let thumbnailSources = _.map(documents, (document) => {
        if (_.get(document, 'type') === DOCUMENT_TYPES.Image) {
            return `/api/image?url=${encodeURIComponent(
                getImageUri(document, entityId, entityType, {
                    scale: publicRuntimeConfig.thumbnailScale,
                    quality: publicRuntimeConfig.thumbnailQuality,
                }),
            )}`;
        }
        return null;
    });
    thumbnailSources = _.compact(thumbnailSources);

    let floorplanThumbnailSources = _.map(documents, (document) => {
        if (_.get(document, 'type') === DOCUMENT_TYPES.Floorplan) {
            return `/api/image?url=${encodeURIComponent(
                getImageUri(document, entityId, entityType, {
                    scale: publicRuntimeConfig.thumbnailScale,
                    quality: publicRuntimeConfig.thumbnailQuality,
                }),
            )}`;
        }
        return null;
    });
    floorplanThumbnailSources = _.compact(floorplanThumbnailSources);

    let featuredAmenitySources = _.map(documents, (document) => {
        if (_.get(document, 'type') === DOCUMENT_TYPES.FeaturedAmenity) {
            return `/api/image?url=${encodeURIComponent(
                getImageUri(document, entityId, entityType),
            )}`;
        }
        return null;
    });
    featuredAmenitySources = _.compact(featuredAmenitySources);

    let featuredAmenityThumbnailSources = _.map(documents, (document) => {
        if (_.get(document, 'type') === DOCUMENT_TYPES.FeaturedAmenity) {
            return `/api/image?url=${encodeURIComponent(
                getImageUri(document, entityId, entityType, {
                    scale: publicRuntimeConfig.thumbnailScale,
                    quality: publicRuntimeConfig.thumbnailQuality,
                }),
            )}`;
        }
        return null;
    });
    featuredAmenityThumbnailSources = _.compact(featuredAmenityThumbnailSources);

    const roomImages = { imageSources, thumbnailSources };
    const floorplanImages = { floorplanSources, floorplanThumbnailSources };
    const featuredAmenityImages = { featuredAmenitySources, featuredAmenityThumbnailSources };

    return { roomImages, floorplanImages, featuredAmenityImages };
}

function generateCityImageUrls(cities) {
    return _.map(cities, (city) => {
        const cityId = _.get(city, 'id');
        const cityDocuments = _.get(city, 'documents');

        const { roomImages } = generateEntityImageSources(cityDocuments, cityId, 'city');

        _.set(city, 'images', _.get(roomImages, 'imageSources'));
        _.set(city, 'thumbnails', _.get(roomImages, 'thumbnailSources'));
        return city;
    });
}

function generateBuildingImageUrls(buildings) {
    return _.map(buildings, (building) => {
        const buildingId = _.get(building, 'id');
        const buildingDocuments = _.get(building, 'documents');

        const { roomImages } = generateEntityImageSources(
            buildingDocuments,
            buildingId,
            'building',
        );

        _.set(building, 'images', _.get(roomImages, 'imageSources'));
        _.set(building, 'thumbnails', _.get(roomImages, 'thumbnailSources'));
        return building;
    });
}

function generateSingleBuildingImageUrls(building) {
    const buildingId = _.get(building, 'id');
    const buildingDocuments = _.get(building, 'documents');

    const { roomImages } = generateEntityImageSources(buildingDocuments, buildingId, 'building');

    _.set(building, 'images', _.get(roomImages, 'imageSources'));
    _.set(building, 'thumbnails', _.get(roomImages, 'thumbnailSources'));
    return building;
}

function generatePortfolioImageUrls(portfolio) {
    const portfolioId = _.get(portfolio, 'id');
    const portfolioDocuments = _.get(portfolio, 'documents');

    const { roomImages } = generateEntityImageSources(portfolioDocuments, portfolioId, 'portfolio');

    _.set(portfolio, 'images', _.get(roomImages, 'imageSources'));
    _.set(portfolio, 'thumbnails', _.get(roomImages, 'thumbnailSources'));
    return portfolio;
}

function generateAgentImageUrls(agent) {
    const agentId = _.get(agent, 'id');
    const agentDocuments = _.get(agent, 'documents');

    const { roomImages } = generateEntityImageSources(agentDocuments, agentId, 'agent');

    _.set(agent, 'images', _.get(roomImages, 'imageSources'));
    _.set(agent, 'thumbnails', _.get(roomImages, 'thumbnailSources'));
    return agent;
}

export default {
    generateCityImageUrls,
    generateBuildingImageUrls,
    generateEntityImageSources,
    generateSingleBuildingImageUrls,
    generatePortfolioImageUrls,
    generateAgentImageUrls,
};
