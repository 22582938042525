import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'portfolio';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setSelectedRoomPortfolio = new CreateAction(reducerName, 'SET_SELECTED_ROOM_PORTFOLIO');
export const setSelectedRoomPortfolioAction = setSelectedRoomPortfolio.action;

const setAllPortfolios = new CreateAction(reducerName, 'SET_ALL_PORTFOLIOS');
export const setAllPortfoliosAction = setAllPortfolios.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    selectedRoomPortfolio: null,
    allPortfolios: null,
};

export default function portfolioReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setSelectedRoomPortfolio.actionType:
            return { ...state, selectedRoomPortfolio: action.payload };
        case setAllPortfolios.actionType:
            return { ...state, allPortfolios: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
