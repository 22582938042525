/* eslint-disable react/no-danger */
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import getConfig from 'next/config';
import TagManager from 'react-gtm-module';
import { compose } from 'redux';
import _ from 'lodash';
import { withApplicationInsights } from 'next-applicationinsights';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/nprogress.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'react-dates/lib/css/_datepicker.css';
import '../../public/assets/css/main.css';
import '../styles/style.css';
import { wrapper } from '../redux/store';
import ErrorPage from './_error';
import '../../utils/lodash-mixins';
import PortfolioNavbar from '../components/portfolio-components/portfolio-navbar.component';
import Navbar from '../components/navbar/navbar.component';
import { themeMui, theme, GlobalStyles } from '../../utils/theme';
import useResponsive from '../helpers/responsive';
import { Image } from '../components/common-components';
import styled from 'styled-components';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
NProgress.configure({ showSpinner: false });

const { publicRuntimeConfig } = getConfig();

const tagManagerArgs = {
    gtmId: publicRuntimeConfig.HOMII_GOOGLE_TAG_MANAGER_ID,
};

const HOMiiApp = ({ Component, pageProps, errorStatusCode }) => {
    const { isMobile, isTablet, isClient } = useResponsive();
    const [unhideZendeskWidget, setUnhideZendeskWidget] = useState(false);
    const [pathName, setPathName] = useState('/');
    const mobileOffsetVertical = pathName === '/bookings' ? 7 : 10.5;
    const mobileOffsetHorizontal = pathName === '/bookings' ? -15 : -5;
    const zendeskPositionHorizontal =
        pathName === '/bookings' && (isMobile || isTablet) ? 'top' : 'bottom';

    useEffect(() => {
        setPathName(_.get(Router, 'router.pathname'));
        if (publicRuntimeConfig.HOMII_FEATURE_ZENDESK) {
            //eslint-disable-next-line no-undef
            zE('webWidget', 'hide');
            setTimeout(() => {
                setUnhideZendeskWidget(true);
            }, 14000);
        }
    }, []);

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        setPathName(_.get(Router, 'router.pathname'));
        if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles);
        TagManager.initialize(tagManagerArgs);
        const hasEft = pathName.includes('/instant-eft');
        if (
            publicRuntimeConfig.HOMII_FEATURE_ZENDESK &&
            unhideZendeskWidget &&
            isClient &&
            !hasEft
        ) {
            //eslint-disable-next-line no-undef
            zE('webWidget', 'show');
            setUnhideZendeskWidget(false);
        }
    });

    const renderNavbar = () => {
        switch (pathName) {
            case '/find-a-space':
                return <Navbar shouldScroll />;
            case '/room/[roomTypeDescription]/[roomTypeId]':
                return <Navbar shouldScroll isRoomPage />;
            case '/bookings':
                return <Navbar shouldScroll isBookingsPage />;
            case '/[portfolio-name]':
                return <PortfolioNavbar />;
            default:
                return <Navbar />;
        }
    };

    const renderComponent = () => {
        const isProd = process.env.NODE_ENV === 'production';

        const baseUrlApp = isProd
            ? publicRuntimeConfig.HOMII_APP_PROD_URL
            : publicRuntimeConfig.HOMII_APP_UAT_URL;

        return (
            <>
                {renderNavbar(pathName)}
                <Component {...pageProps} baseUrlApp={baseUrlApp} />
            </>
        );
    };

    const renderError = () => {
        return <ErrorPage statusCode={errorStatusCode} />;
    };

    const renderHommiProcessComponent = () => {
        return (
            <>
                <Head>
                    <title>Process...</title>
                </Head>
                <MuiThemeProvider theme={themeMui}>
                    <ThemeProvider theme={theme}>
                        <StylesProvider injectFirst>
                            <GlobalStyles />
                            <Component {...pageProps} />
                        </StylesProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </>
        );
    };

    const renderHommiEft = () => {
        return (
            <>
                <Head>
                    <title>Instant EFT</title>
                </Head>
                <MuiThemeProvider theme={themeMui}>
                    <ThemeProvider theme={theme}>
                        <StylesProvider injectFirst>
                            <GlobalStyles />
                            <EFTContainer>
                                <EFTLogo>
                                    <Image
                                        src="/assets/images/homii_logo.png"
                                        alt="HOMii Logo"
                                        id="homii-logo-link"
                                        height={22}
                                        width={82.25}
                                    />
                                </EFTLogo>
                                <EFTContainerText>
                                    Instant EFT is currently not available. Please use the Credit or
                                    Debit Card option.
                                </EFTContainerText>
                            </EFTContainer>
                        </StylesProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </>
        );
    };

    const renderHommi = () => {
        return (
            <>
                <Head>
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `window.zESettings = {
                                webWidget: {
                                    offset: {
                                        mobile: {
                                                    horizontal: '${mobileOffsetHorizontal}px',
                                                    vertical: '${mobileOffsetVertical}px'
                                                }
                                    },
                                    position: { horizontal: 'right', vertical: '${zendeskPositionHorizontal}' },
                                    }
                        
                            };`,
                        }}
                    />
                </Head>
                <MuiThemeProvider theme={themeMui}>
                    <ThemeProvider theme={theme}>
                        <StylesProvider injectFirst>
                            <GlobalStyles />
                            {errorStatusCode ? renderError() : renderComponent()}
                        </StylesProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </>
        );
    };

    const renderHommiProcess = () => {
        return <>{errorStatusCode ? renderError() : renderHommiProcessComponent()}</>;
    };

    const renderMain = () => {
        const hasProcess = pathName.includes('/process');
        const hasEft = pathName.includes('/instant-eft');
        if (hasEft) return renderHommiEft();
        return <>{hasProcess ? renderHommiProcess() : renderHommi()}</>;
    };

    return renderMain();
};

HOMiiApp.getInitialProps = async ({ Component, ctx }) => {
    const { dispatch, getState } = ctx.store;

    try {
        const pageProps = Component.getInitialProps
            ? await Component.getInitialProps(ctx, dispatch, getState)
            : {};

        return { pageProps };
    } catch (error) {
        if (_.get(error, 'response.status') === 503 || _.get(error, 'shouldShowErrorPage')) {
            return { errorStatusCode: _.get(error, 'response.status') };
        }
        throw error;
    }
};

export default compose(
    withApplicationInsights({
        instrumentationKey: publicRuntimeConfig.HOMII_APP_INSIGHTS_INSTRUMENTATION_KEY,
        isEnabled: process.env.NODE_ENV === 'production',
    }),
    wrapper.withRedux,
)(HOMiiApp);

HOMiiApp.propTypes = {
    pageProps: PropTypes.object,
    errorStatusCode: PropTypes.number,
    Component: PropTypes.any,
};

const EFTLogo = styled.div`
    position: absolute;
    top: 15px;
    left: 30px;
    z-index: 1;
`;

const EFTContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 27px;
    flex: 1;
    margin-top: ${(props) => (props.split ? '20px' : '40px')};
    max-width: 100%;
    background-color: #181d1f;
    .full-screen-app-bar {
        background-color: #181d1f;
        z-index: 10;
    }
    .MuiPaper-root {
        background-color: #181d1f;
    }
`;

const EFTContainerText = styled.p`
    font-size: 25px;
    line-height: 1.6;
    margin-top: 15px;
    margin-bottom: 16px;
    line-height: 1.65;
    letter-spacing: 0.1px;
`;
