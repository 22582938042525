import networkService from '../network-service/network.service';
import preBookingUrls from './pre-booking-service-urls';

function getPreBookingQuote(
    roomTypeId,
    optionalExtras,
    startDate,
    endDate,
    numberOfOccupants,
    forGender,
) {
    const url = preBookingUrls.preBookingQuoteUrl();
    const data = numberOfOccupants
        ? { roomTypeId, optionalExtras, startDate, endDate, numberOfOccupants, forGender }
        : { roomTypeId, optionalExtras, startDate, endDate, forGender };
    return networkService.makePost(url, data);
}

function createPreBooking(
    roomTypeId,
    optionalExtras,
    startDate,
    endDate,
    expectProvisionalBooking,
    numberOfOccupants,
    referrer,
) {
    const url = preBookingUrls.preBookingUrl();
    const data = numberOfOccupants
        ? {
              roomTypeId,
              optionalExtras,
              startDate,
              endDate,
              numberOfOccupants,
              expectProvisionalBooking,
              referrer,
          }
        : { roomTypeId, optionalExtras, startDate, endDate, expectProvisionalBooking, referrer };
    return networkService.makePost(url, data);
}

function cancelPreBooking(preBookingId) {
    const url = preBookingUrls.cancelPreBookingUrl();
    const data = { preBookingId };
    return networkService.makePost(url, data);
}

function preBookingStartCreditCardPayment(useExistingCard, preBookingId) {
    const url = preBookingUrls.preBookingStartCreditCardPayment();
    const data = { useExistingCard, preBookingId };
    return networkService.makePost(url, data);
}

function preBookingStartEftPayment(redirectUrl, preBookingId) {
    const url = preBookingUrls.preBookingStartEftPayment();
    const data = { redirectUrl, preBookingId };
    return networkService.makePost(url, data);
}

function postWaitingList(
    roomTypeId,
    firstName,
    lastName,
    phoneNumber,
    email,
    requestedBookingStartDate,
    requestedBookingEndDate,
) {
    const url = preBookingUrls.waitingListUrl();
    const data = {
        roomTypeId,
        firstName,
        lastName,
        phoneNumber,
        email,
        requestedBookingStartDate,
        requestedBookingEndDate,
    };
    return networkService.makePost(url, data);
}

function createUserPreBooking(
    roomTypeId,
    optionalExtras,
    startDate,
    endDate,
    expectProvisionalBooking,
    numberOfOccupants,
    referrer,
    personId,
) {
    const url = preBookingUrls.userPreBookingUrl();
    const data = numberOfOccupants
        ? {
              roomTypeId,
              optionalExtras,
              startDate,
              endDate,
              numberOfOccupants,
              expectProvisionalBooking,
              referrer,
              personId,
          }
        : {
              roomTypeId,
              optionalExtras,
              startDate,
              endDate,
              expectProvisionalBooking,
              referrer,
              personId,
          };
    return networkService.makePost(url, data);
}

export default {
    getPreBookingQuote,
    createPreBooking,
    cancelPreBooking,
    preBookingStartCreditCardPayment,
    preBookingStartEftPayment,
    postWaitingList,
    createUserPreBooking,
};
