import Head from 'next/head';
import getConfig from 'next/config';
import PropTypes from 'prop-types';

const { publicRuntimeConfig } = getConfig();

const MetaData = (props) => {
    const { title, desc, canonical, isHome, imgSrc } = props;
    const seoTitle = title || '';
    const isProd = process.env.NODE_ENV === 'production';

    if (typeof window !== 'undefined' && isProd) {
        // eslint-disable-next-line global-require
        const ReactPixel = require('react-facebook-pixel');
        ReactPixel.default.init(
            publicRuntimeConfig.HOMII_FACEBOOK_PIXEL,
            {},
            { debug: !isProd, autoConfig: false },
        );
        ReactPixel.default.pageView();
        ReactPixel.default.fbq('track', 'PageView');
    }

    const renderHomeElements = () => {
        if (!isHome) {
            return null;
        }
        return (
            <>
                <meta property="og:title" content={title} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={desc} />
                <meta property="og:image" content={imgSrc} />
                <meta property="og:url" content={canonical} />
            </>
        );
    };

    return (
        <Head>
            <title>{seoTitle}</title>
            <meta name="description" content={desc} />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta name="apple-itunes-app" content="app-id=1478894335" />
            <link rel="manifest" href="/public/manifest.json" />
            {canonical && <link rel="canonical" href={`${canonical}`} />}
            {renderHomeElements()}
        </Head>
    );
};

export default MetaData;

MetaData.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    canonical: PropTypes.string,
    imgSrc: PropTypes.string,
    isHome: PropTypes.bool,
};
