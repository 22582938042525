import networkService from '../network-service/network.service';
import buildingUrls from './building-service-urls';

function getBuilding() {
    const url = buildingUrls.getBuildingUrl();
    return networkService.makeGet(url);
}

function getAllBuildings() {
    const url = buildingUrls.getAllBuildingsUrl();
    return networkService.makeGet(url);
}

function getBuildingByCityId(cityId) {
    const url = buildingUrls.getBuildingByCityIdUrl(cityId);
    return networkService.makeGet(url);
}

function getBuildingById(buildingId) {
    const url = buildingUrls.getBuildingByIdUrl(buildingId);
    return networkService.makeGet(url);
}

export default {
    getBuilding,
    getAllBuildings,
    getBuildingByCityId,
    getBuildingById,
};
