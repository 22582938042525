import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

const FadeInAnimation = (props) => {
    const { children, wrapDisabled, onlyFadeIn } = props;
    if (wrapDisabled) {
        return (
            <Fade bottom cascade duration={750} delay={250}>
                {children}
            </Fade>
        );
    }
    if (onlyFadeIn) {
        return (
            <Fade duration={750} delay={250}>
                {children}
            </Fade>
        );
    }
    return (
        <Fade bottom cascade duration={750} delay={250}>
            <div>{children}</div>
        </Fade>
    );
};

FadeInAnimation.propTypes = {
    children: PropTypes.any,
    wrapDisabled: PropTypes.bool,
    onlyFadeIn: PropTypes.bool,
};

export default FadeInAnimation;
