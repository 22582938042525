import localStorageService from '../storage-service/local-storage.service';

const USER_ACCESS_TOKEN_KEY = '@UserAccessToken';

function getUserAccessToken() {
    return localStorageService.getItem(USER_ACCESS_TOKEN_KEY);
}

function setUserAccessToken(token) {
    return localStorageService.setItem(USER_ACCESS_TOKEN_KEY, token);
}

function clearUserAccessToken() {
    return localStorageService.clear(USER_ACCESS_TOKEN_KEY);
}

export default {
    getUserAccessToken,
    setUserAccessToken,
    clearUserAccessToken,
};
