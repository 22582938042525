import getConfig from 'next/config';
import localStorageService from '../storage-service/local-storage.service';

const { publicRuntimeConfig } = getConfig();

const ENV_TYPE__KEY = '@homiiEnvType';

function saveEnvType(envType) {
    return localStorageService.setItem(ENV_TYPE__KEY, envType);
}

function loadEnvType() {
    return localStorageService.getItem(ENV_TYPE__KEY);
}

async function getBaseUrl() {
    return publicRuntimeConfig.HOMII_API_URL;
}

async function getApiKey() {
    return publicRuntimeConfig.HOMII_API_KEY;
}

export default {
    saveEnvType,
    loadEnvType,
    getBaseUrl,
    getApiKey,
};
