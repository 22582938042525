import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { analyticsService } from '../../services/homii-services';

export default class Layout extends Component {
    static propTypes = {
        children: PropTypes.any,
    };

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            if (!window.GA_INITIALIZED) {
                analyticsService.initGoogleAnalytics();
                window.GA_INITIALIZED = true;
            }
            analyticsService.logPageView();
        }
    }

    render() {
        return <div>{this.props.children}</div>;
    }
}
