import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Image as HOMiiImage } from '../common-components';

const ProgressiveImage = (props) => {
    const { thumbnailSrc, src, alt, ...rest } = props;
    const [currentImage, setCurrentImage] = useStateIfMounted(thumbnailSrc);
    const [loading, setLoading] = useStateIfMounted(true);

    useEffect(() => {
        fetchImage(currentImage);
    }, []);

    const fetchImage = async () => {
        const image = new Image();
        image.onload = () => {
            setCurrentImage(src);
            setTimeout(() => {
                setLoading(false);
            }, 700);
        };

        image.src = src;
    };

    const renderSingleImage = () => {
        return <StyledImage isOldImage src={currentImage} $loading={loading} alt={alt} {...rest} />;
    };

    return <BlurContainer>{renderSingleImage()}</BlurContainer>;
};

export default ProgressiveImage;

ProgressiveImage.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    thumbnailSrc: PropTypes.string,
};

const BlurContainer = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 5px;
`;

const StyledImage = styled(HOMiiImage)`
    transition: ${({ $loading }) => $loading && '0.9s filter linear'};
    filter: ${({ $loading }) => $loading && 'blur(40px)'};
`;
