/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Link from 'next/link';

import useResponsive from '../../helpers/responsive';

const LinkUnderlineAnimation = (props) => {
    const { children, href, id, large } = props;
    const { isMobile, isTablet } = useResponsive();

    return (
        <Link href={href} passHref>
            <div>
                {!large && (
                    <Linkunderline href={href} id={id} isAnimated={isMobile}>
                        {children}
                    </Linkunderline>
                )}
                {large && (
                    <LinkunderlineLarge href={href} isMobile={isMobile} isTablet={isTablet} id={id}>
                        {children}
                    </LinkunderlineLarge>
                )}
            </div>
        </Link>
    );
};

LinkUnderlineAnimation.propTypes = {
    children: PropTypes.any,
};

export default LinkUnderlineAnimation;

LinkUnderlineAnimation.propTypes = {
    href: PropTypes.string,
    id: PropTypes.string,
    large: PropTypes.bool,
};

const SlideAnimation = keyframes`
    0% { width: 0%; left: 0%; background: #243239;} 
    10%{ width: 30%; left: 2%; background: #243239;}
    20%{ width: 48%; left: 7%; background: #243239;}
    30%{ width: 60%; left: 13%; background: #243239;}
    40%{ width: 50%; left: 27%; background: #243239;}
    50%{ width: 40%; left: 48%; background: #243239;}
    60%{ width: 15%; left: 75%; background: #243239;}
    70%{ width: 12%; left: 83%; background: #243239;}
    80%{ width: 3%; left: 95%;background: #243239;}
    90%{ width: 1%; left: 99%; background: #243239;}
    100% { width: 0%; left: 100%; background: #243239;} 
`;

const Linkunderline = styled.a`
    text-decoration: ${(props) => (props.isAnimated ? 'underline' : 'none')};
    color: ${(props) => props.theme.whiteout};
    font-weight: bold;
    position: relative;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        display: block;
        left: 0;
        bottom: 0;
        background: ${(props) => (props.isAnimated ? 'none' : props.theme.primaryLight)};
        margin-top: 5px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        display: block;
        bottom: 0;
        left: 0;
        background: ${(props) => (props.isAnimated ? 'none' : props.theme.primaryLight)};
        margin-top: 5px;
    }

    &:hover::after {
        animation-name: ${SlideAnimation};
        animation-delay: 0s;
        animation-timing-function: ease;
        animation-duration: 0.5s;
    }
`;

const LinkunderlineLarge = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.white};
    font-weight: bold;
    position: relative;
    cursor: pointer;
    font-size: ${(props) => (props.isMobile ? '20.16' : props.isTablet ? '27.65' : '39.06')}px;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: ${(props) => (props.isMobile || props.isTablet ? 2 : 3)}px;
        display: block;
        left: 0;
        bottom: 0;
        background: ${(props) => props.theme.primaryLight};
        margin-top: 5px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: ${(props) => (props.isMobile || props.isTablet ? 2 : 3)}px;
        display: block;
        bottom: 0;
        left: 0;
        background: ${(props) => props.theme.primaryLight};
        margin-top: 5px;
    }

    &:hover::after {
        animation-name: ${SlideAnimation};
        animation-delay: 0s;
        animation-timing-function: ease;
        animation-duration: 0.5s;
    }

    &:hover {
        color: ${(props) => props.theme.primaryLight};
    }
`;
