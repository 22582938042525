const WordPressImageTransformer = (wpUrl, width, quality) => {
    if (wpUrl == undefined || wpUrl == null) return '';
    const indexPos = wpUrl.lastIndexOf('/') + 1;
    const featuredImage = `${wpUrl.slice(0, indexPos)}${wpUrl.slice(
        indexPos,
    )}?resize=width:${width}/quality=value:${quality}`;
    return featuredImage;
};

const WordPressTransformHeight = (wpUrl, height) => {
    if (wpUrl == undefined || wpUrl == null) return '';
    const indexPos = wpUrl.lastIndexOf('/') + 1;
    const featuredImage = `${wpUrl.slice(0, indexPos)}${wpUrl.slice(
        indexPos,
    )}?resize=height:${height}`;
    return featuredImage;
};

const WordPressThumbnail = (wpUrl, height, width) => {
    if (wpUrl == undefined || wpUrl == null) return '';
    const indexPos = wpUrl.lastIndexOf('/') + 1;
    const featuredImage = `${wpUrl.slice(0, indexPos)}${wpUrl.slice(
        indexPos,
    )}?resize=height:${height},width:${width}`;
    return featuredImage;
};

export { WordPressImageTransformer, WordPressTransformHeight, WordPressThumbnail };
