import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useResponsive from '../../helpers/responsive';

const useStyles = makeStyles((theme) => ({
    carouselContainerSettings: {
        maxWidth: 'calc(1320px + (100% - 1320px)/2)',
        [theme.breakpoints.between(1401, 'xl')]: {
            maxWidth: 'calc(1600px + (100% - 1600px)/2)',
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: 'calc(1320px + (100% - 1320px)/2)',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 1320,
        },
    },

    carouselContainerBuildingsSettings: {
        maxWidth: 1400,
        [theme.breakpoints.between(1401, '1500')]: {
            maxWidth: 1380,
        },
        [theme.breakpoints.between(1501, '100')]: {
            maxWidth: 1460,
        },
        [theme.breakpoints.between(1601, '1700')]: {
            maxWidth: 1500,
        },
        [theme.breakpoints.between(1701, 'xl')]: {
            maxWidth: 1620,
        },
    },
}));

const CarouselContainer = (props) => {
    const { children, isBuilding } = props;
    const { isMobile, isTablet } = useResponsive();
    const classes = useStyles();

    return (
        <StyledContainer
            maxWidth="lg"
            classes={{
                maxWidthLg: isBuilding
                    ? classes.carouselContainerBuildingsSettings
                    : classes.carouselContainerSettings,
            }}
            $isMobile={isMobile}
            $isTablet={isTablet}
        >
            {children}
        </StyledContainer>
    );
};

export default CarouselContainer;

CarouselContainer.propTypes = {
    isBuilding: PropTypes.bool,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
    children: PropTypes.any,
};

const StyledContainer = styled(Container)`
    padding-right: 0px;
    margin-right: 0px;
    margin-left: ${(props) =>
        props.$isMobile || props.$isTablet ? 'unset' : 'calc((100% - 1320px) / 2)'};
`;
