import axios from 'axios';
import _ from 'lodash';
import getConfig from 'next/config';

import userTokenService from '../user-token-service/user-token.service';
import createBaseUrlInterceptor from './base-url-interceptor';
import createApiKeyInterceptor from './api-key-interceptor';

const { publicRuntimeConfig } = getConfig();
const axiosInstance = axios.create();
createBaseUrlInterceptor(axiosInstance);
createApiKeyInterceptor(axiosInstance);

axiosInstance.interceptors.request.use(
    (requestConfig) => {
        const { method, url, data, headers } = requestConfig;
        console.log(`🤔 ${method.toUpperCase()} ${url}`, { data, headers }); // eslint-disable-line no-console
        return requestConfig;
    },
    (error) => {
        console.log('❌', error); // eslint-disable-line no-console
        return Promise.reject(error);
    },
);
axiosInstance.interceptors.response.use(
    (response) => {
        const {
            data,
            headers,
            config: { url, method },
        } = response;
        console.log(`✅ ${method.toUpperCase()} "${url}"`, { data, headers }); // eslint-disable-line no-console
        return response;
    },
    (error) => {
        console.log('❌', error); // eslint-disable-line no-console
        return Promise.reject(error);
    },
);

function makeGet(url, config) {
    return getDefaultConfig().then((defaultConfig) => {
        const responseManipulator = _.get(
            config,
            'responseManipulator',
            defaultConfig.responseManipulator,
        );
        const { defaultRequestConfig } = defaultConfig;
        const requestConfig = _.merge(defaultRequestConfig, config);

        return axiosInstance
            .get(url, requestConfig)
            .then((response) => responseManipulator(response));
    });
}

function makePost(url, data, config) {
    return getDefaultConfig().then((defaultConfig) => {
        const responseManipulator = _.get(
            config,
            'responseManipulator',
            defaultConfig.responseManipulator,
        );
        const { defaultRequestConfig } = defaultConfig;
        const requestConfig = _.merge(defaultRequestConfig, config);

        return axiosInstance
            .post(url, data, requestConfig)
            .then((response) => responseManipulator(response));
    });
}

function makePut(url, data, config) {
    return getDefaultConfig().then((defaultConfig) => {
        const responseManipulator = _.get(
            config,
            'responseManipulator',
            defaultConfig.responseManipulator,
        );
        const { defaultRequestConfig } = defaultConfig;
        const requestConfig = _.merge(defaultRequestConfig, config);

        return axiosInstance
            .put(url, data, requestConfig)
            .then((response) => responseManipulator(response));
    });
}

function makeDelete(url, data, config) {
    return getDefaultConfig().then((defaultConfig) => {
        const responseManipulator = _.get(
            config,
            'responseManipulator',
            defaultConfig.responseManipulator,
        );
        const { defaultRequestConfig } = defaultConfig;
        let requestConfig = _.merge(defaultRequestConfig, config);
        requestConfig = _.merge(requestConfig, { data });

        return axiosInstance
            .delete(url, requestConfig)
            .then((response) => responseManipulator(response));
    });
}

async function getDefaultConfig() {
    const userToken = await userTokenService.getUserAccessToken();
    const defaultRequestConfig = {
        headers: {
            'api-version': publicRuntimeConfig.HOMII_API_VERSION,
        },
    };

    if (userToken) {
        _.set(defaultRequestConfig, 'headers.Authorization', `Bearer ${userToken}`);
    }

    return {
        defaultRequestConfig,
        responseManipulator: (response) => {
            const requestId = response.headers['x-request-id'];
            if (typeof response.data.result === 'object') {
                response.data.result.requestId = response.headers['x-request-id'];
                return response.data.result;
            }

            return { result: response.data.result, requestId };
        },
    };
}

export default {
    makeGet,
    makePost,
    makeDelete,
    makePut,
};
