const roomTypeBaseUrl = '/RoomType';

export default {
    searchAvailable: (cityId, startDate, endDate) =>
        `${roomTypeBaseUrl}/searchAvailable/byCity?CityId=${cityId}&StartDate=${startDate}&EndDate=${endDate}`,
    roomTypeAvailabilityUrl: (roomTypeId, startDate, endDate) =>
        `${roomTypeBaseUrl}/availability?RoomTypeId=${roomTypeId}&StartDate=${startDate}&EndDate=${endDate}`,
    roomTypeByBuildingIdUrl: (buildingId) =>
        `${roomTypeBaseUrl}/byBuildingId?BuildingId=${buildingId}`,
    roomTypeSearchAvailableByBuildingUrl: (buildingId, startDate, endDate) =>
        `${roomTypeBaseUrl}/searchAvailable/byBuilding?BuildingId=${buildingId}&StartDate=${startDate}&EndDate=${endDate}`,
    roomTypeSearchAvailableByCoordinatesUrl: (latitude, longitude, startDate, endDate) =>
        `${roomTypeBaseUrl}/searchAvailable/byCoordinates?Latitude=${latitude}&Longitude=${longitude}&StartDate=${startDate}&EndDate=${endDate}`,
    roomTypeUrl: () => `${roomTypeBaseUrl}`,
    roomTypeByCityIdUrl: (cityId) => `${roomTypeBaseUrl}/byCityId?CityId=${cityId}`,
    roomTypeByIdUrl: (roomTypeId) => `${roomTypeBaseUrl}/byId?RoomTypeId=${roomTypeId}`,
    roomTypeOptionalExtrasUrl: (roomTypeId) =>
        `${roomTypeBaseUrl}/optionalExtras?RoomTypeId=${roomTypeId}`,
    roomTypeByPortfolioIdUrl: (portfolioId) =>
        `${roomTypeBaseUrl}/byPortfolioId?PortfolioId=${portfolioId}`,
    roomTypeSearchAvailableByFilterUrl: () => `${roomTypeBaseUrl}/searchAvailable/byFilters`,
    roomTypeSummaryAvailableByBuildingUrl: (buildingId) =>
        `${roomTypeBaseUrl}/summary/byBuilding?BuildingId=${buildingId}`,
};
