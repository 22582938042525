import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

const MoreMenuComponent = () => {
    return (
        <Grid container spacing={3}>
            <Grid item md={6} lg={3}>
                <StyledHeader5 marginBottom={15}>About</StyledHeader5>
                <List>
                    <ListItem button className="menu-list-item" id="menu-link-what-is-homii">
                        <ListItemText primary="What is HOMii?" className="menu-link" />
                    </ListItem>
                </List>
            </Grid>
            <Grid item md={6} lg={3}>
                <StyledHeader5 marginBottom={15}>HOMii Happenings</StyledHeader5>
                <List>
                    <ListItem button className="menu-list-item" id="menu-link-live">
                        <ListItemText primary="#Live" className="menu-link" />
                    </ListItem>
                    <ListItem button className="menu-list-item" id="menu-link-work">
                        <ListItemText primary="#Work" className="menu-link" />
                    </ListItem>
                    <ListItem button className="menu-list-item" id="menu-link-play">
                        <ListItemText primary="#Play" className="menu-link" />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs />
        </Grid>
    );
};

export default MoreMenuComponent;

const StyledHeader5 = styled.h5`
    margin-bottom: ${(props) => props.marginBottom}px;
`;
