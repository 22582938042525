import envService from '../env-service/env.service';

function createBaseUrlInterceptor(axios) {
    axios.interceptors.request.use(
        async (_config) => {
            const config = _config;
            config.baseURL = await envService.getBaseUrl();
            return config;
        },
        (error) => Promise.reject(error),
    );
}

export default createBaseUrlInterceptor;
