import { useState, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
    const [isClient, setIsClient] = useState(false);

    const isVerySmallMobile = useMediaQuery({
        maxWidth: 330,
    });

    const isSmallerMobile = useMediaQuery({
        maxWidth: 380,
    });

    const isSmallMobile = useMediaQuery({
        maxWidth: 450,
    });

    const isSmallBreakpoint = useMediaQuery({
        maxWidth: 599,
    });

    const isMobile = useMediaQuery({
        maxWidth: 700,
    });

    const isSmallTablet = useMediaQuery({
        maxWidth: 925,
    });

    const isTablet = useMediaQuery({
        maxWidth: 1100,
    });

    const isLargeTablet = useMediaQuery({
        maxWidth: 1320,
    });

    const isSmallDesktop = useMediaQuery({
        maxWidth: 1450,
    });

    const isDesktop = useMediaQuery({
        maxWidth: 2400,
    });

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') setIsClient(true);
    }, []);

    return {
        isMobile: isClient ? isMobile : true,
        isTablet: isClient ? isTablet : false,
        isLargeTablet: isClient ? isLargeTablet : false,
        isSmallDesktop: isClient ? isSmallDesktop : false,
        isDesktop: isClient ? isDesktop : false,
        isSmallTablet: isClient ? isSmallTablet : false,
        isVerySmallMobile: isClient ? isVerySmallMobile : false,
        isSmallMobile: isClient ? isSmallMobile : false,
        isSmallerMobile: isClient ? isSmallerMobile : false,
        isSmallBreakpoint: isClient ? isSmallBreakpoint : false,
        isClient,
    };
};

export default useResponsive;
