import marketingUrls from './marketing-service-urls';
import networkService from '../network-service/network.service';

function submitWebsiteVisited(referrer) {
    const url = marketingUrls.websiteVisitedUrl();
    const data = { referrer };
    return networkService.makePost(url, data);
}

function getMarketingCampaignDataByName(campaignName) {
    const url = marketingUrls.getMarketingCampaignDataByNameUrl(campaignName);
    return networkService.makeGet(url);
}

export default {
    submitWebsiteVisited,
    getMarketingCampaignDataByName,
};
