import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'account';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setUserId = new CreateAction(reducerName, 'SET_USER_ID');
export const setUserIdAction = setUserId.action;

const setPersonId = new CreateAction(reducerName, 'SET_PERSON_ID');
export const setPersonIdAction = setPersonId.action;

const setDidSignUp = new CreateAction(reducerName, 'SET_DID_SIGN_UP');
export const setDidSignUpAction = setDidSignUp.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    userId: '',
    personId: '',
    didSignUp: false,
};

export default function accountReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setUserId.actionType:
            return { ...state, userId: action.payload };
        case setPersonId.actionType:
            return { ...state, personId: action.payload };
        case setDidSignUp.actionType:
            return { ...state, didSignUp: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
