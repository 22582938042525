export default function buildActionType(_reducerName = '', _actionName = '') {
    let reducerName = _reducerName;
    let actionName = _actionName;
    reducerName = reducerName.toString().trim();
    if (!reducerName) {
        throw new Error('Reducer name cannot be blank');
    }
    actionName = actionName.toString().trim();
    if (!actionName) {
        throw new Error('Action name cannot be blank');
    }
    return `${reducerName}/${actionName}`;
}
