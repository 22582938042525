import _ from 'lodash';

import userTokenService from '../user-token-service/user-token.service';
import accountUrls from './account-service-urls';
import networkService from '../network-service/network.service';

function login(email, password) {
    const url = accountUrls.loginUrl();
    const userData = { email, password };
    return networkService.makePost(url, userData).then((data) => {
        const userAccessToken = _.get(data, 'accessToken');
        userTokenService.setUserAccessToken(userAccessToken);
        return data;
    });
}

function signUp(firstName, lastName, email, phoneNumber, password, confirmPassword) {
    const url = accountUrls.registerAndLoginUrl();
    const userData = { firstName, lastName, email, phoneNumber, password, confirmPassword };
    return networkService.makePost(url, userData).then((data) => {
        const userAccessToken = _.get(data, 'accessToken');
        userTokenService.setUserAccessToken(userAccessToken);
        return data;
    });
}

function setGender(gender) {
    const url = accountUrls.setGenderUrl();
    const data = { gender };
    return networkService.makePost(url, data);
}

function getPersonProfile() {
    const url = accountUrls.getPersonProfileUrl();
    return networkService.makeGet(url);
}

function getPersonHasAcceptedTermsAndCondtitions(portfolioId) {
    const url = accountUrls.getPersonHasAcceptedTermsAndCondtitionsUrl(portfolioId);
    return networkService.makeGet(url);
}

function setPersonHasAcceptedTermsAndCondtitions(portfolioId) {
    const url = accountUrls.setPersonHasAcceptedTermsAndCondtitionsUrl();
    const data = { portfolioId };
    return networkService.makePost(url, data);
}

function forgotPassword(emailAddress) {
    const url = accountUrls.requestPasswordResetUrl();
    const data = { emailAddress };
    return networkService.makePost(url, data);
}

function resetPassword(emailAddress, newPassword, passwordResetOtp) {
    const url = accountUrls.resetPasswordUrl();
    const data = { emailAddress, newPassword, passwordResetOtp };
    return networkService.makePost(url, data);
}

export default {
    login,
    signUp,
    setGender,
    getPersonProfile,
    getPersonHasAcceptedTermsAndCondtitions,
    setPersonHasAcceptedTermsAndCondtitions,
    forgotPassword,
    resetPassword,
};
