const accountBaseUrl = '/User';
const personBaseUrl = '/Person';

export default {
    loginUrl: () => `${accountBaseUrl}/login`,
    registerAndLoginUrl: () => `${accountBaseUrl}/login/register`,
    setGenderUrl: () => `${personBaseUrl}/gender`,
    getPersonProfileUrl: () => `${personBaseUrl}`,
    getPersonHasAcceptedTermsAndCondtitionsUrl: (portfolioId) =>
        `${personBaseUrl}/hasAcceptedTermsAndConditions?PortfolioId=${portfolioId}`,
    setPersonHasAcceptedTermsAndCondtitionsUrl: () => `${personBaseUrl}/acceptTermsAndConditions`,
    requestPasswordResetUrl: () => `${accountBaseUrl}/requestPasswordReset`,
    resetPasswordUrl: () => `${accountBaseUrl}/resetPassword`,
};
