import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Card = (props) => {
    const { borderRadius, padding } = props;
    return (
        <Container borderRadius={borderRadius} padding={padding}>
            {this.props.children}
        </Container>
    );
};

Card.propTypes = {
    children: PropTypes.any,
    borderRadius: PropTypes.number,
    padding: PropTypes.number,
};

Card.defaultProps = {
    borderRadius: 5,
    padding: 20,
};

const Container = styled.div`
    position: relative;
    z-index: 1;
    background: ${(props) => props.theme.white};
    max-width: 500px;
    margin: 0 auto 100px;
    padding: ${(props) => props.padding}px;
    text-align: center;
    border-radius: ${(props) => props.borderRadius}px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
`;

export default Card;
