import networkService from '../network-service/network.service';
import cityUrls from './city-service-urls';

function getAllCities() {
    const url = cityUrls.getAllCitiesUrl();
    return networkService.makeGet(url);
}

function getCity(cityId) {
    const url = cityUrls.getCityUrl(cityId);
    return networkService.makeGet(url);
}

function getClosestCity(latitude, longitude, portfolioId) {
    const url = cityUrls.getClosestCityUrl(latitude, longitude, portfolioId);
    return networkService.makeGet(url);
}

export default {
    getAllCities,
    getCity,
    getClosestCity,
};
