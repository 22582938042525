import envService from '../env-service/env.service';

function createApiKeyInterceptor(axios) {
    axios.interceptors.request.use(
        async (_config) => {
            const config = _config;
            config.headers['api-key'] = await envService.getApiKey();
            return config;
        },
        (error) => Promise.reject(error),
    );
}

export default createApiKeyInterceptor;
