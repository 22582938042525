import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = (props) => {
    const { children, type, id, small } = props;
    switch (type) {
        case 'green':
            return (
                <ApartmentTypeLabelGreen small={small} id={id}>
                    {_.upperCase(children)}
                </ApartmentTypeLabelGreen>
            );
        case 'white':
            return (
                <ApartmentTypeLabelWhite small={small} id={id}>
                    {_.upperCase(children)}
                </ApartmentTypeLabelWhite>
            );
        default:
            return (
                <ApartmentTypeLabel small={small} type={type} id={id}>
                    {_.upperCase(children)}
                </ApartmentTypeLabel>
            );
    }
};

Label.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    type: PropTypes.string,
    id: PropTypes.string,
    small: PropTypes.bool,
};

export default Label;

const ApartmentTypeLabel = styled.p`
    background-color: ${(props) => props.theme.darkText};
    border-radius: 7px;
    padding: ${(props) => (props.small ? '3px 8px 2px 8px' : '8px')};
    color: ${(props) => props.theme.searchButtonText};
    font-size: 11px;
    display: inline-block;
    margin: 0;
    font-weight: bold;
    background-color: ${(props) => props.theme.darkText};
    border: 1px solid ${(props) => props.theme.darkText};
`;

const ApartmentTypeLabelGreen = styled(ApartmentTypeLabel)`
    background-color: ${(props) => props.theme.primaryLight};
    border: 1px solid ${(props) => props.theme.primaryLight};
`;

const ApartmentTypeLabelWhite = styled(ApartmentTypeLabel)`
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.darkerWhiteout};
    color: ${(props) => props.theme.darkerWhiteout};
`;
