import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'marketingReducer';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setCampaignData = new CreateAction(reducerName, 'SET_CAMPAIGN_DATA');
export const setCampaignDataAction = setCampaignData.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    campaignData: null,
};

export default function marketingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setCampaignData.actionType:
            return { ...state, campaignData: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
