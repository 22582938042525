import React, { Component } from 'react';
import Select, { components } from 'react-select';
import styled, { withTheme } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { boundMethod } from 'autobind-decorator';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Option = (props) => {
    const icon = _.get(props, 'data.icon');
    const label = _.get(props, 'data.label');
    const subLabel = _.get(props, 'data.subLabel');
    return (
        <components.Option {...props}>
            <CustomOptionsContainer>
                {icon}
                <Col>
                    {label}
                    {subLabel && <SubLabelWrapper>{subLabel}</SubLabelWrapper>}
                </Col>
            </CustomOptionsContainer>
        </components.Option>
    );
};

class SearchBarDropdown extends Component {
    static propTypes = {
        options: PropTypes.array,
        defaultSelected: PropTypes.object,
        checkbox: PropTypes.bool,
        placeholder: PropTypes.string,
        selectId: PropTypes.string,
        theme: PropTypes.object,

        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
        };
    }

    componentDidMount() {
        const { defaultSelected } = this.props;
        if (!_.isEmpty(defaultSelected)) {
            this.setState({ selectedOption: defaultSelected });
        }
    }

    componentDidUpdate(prevProps) {
        const { defaultSelected } = this.props;
        if (prevProps.defaultSelected !== defaultSelected && !_.isEmpty(defaultSelected)) {
            this.setState({ selectedOption: defaultSelected });
        }
    }

    parseOptions() {
        const { options, checkbox, onChange } = this.props;
        if (!checkbox) {
            return options;
        }

        _.map(options, (_option) => {
            const option = _option;
            option.icon = (
                <CheckboxWrapper>
                    <Checkbox
                        checked={this.getChecked(option)}
                        onChange={() => onChange(option.value)}
                        disableRipple
                    />
                </CheckboxWrapper>
            );
        });
        return options;
    }

    getChecked(option) {
        const { selectedOption } = this.state;
        if (!_.isNil(option) && !_.isNil(selectedOption)) {
            if (_.isEqual(selectedOption.label, option.label)) {
                return true;
            }
        }
        return false;
    }

    @boundMethod
    handleChange(selectedOption) {
        const { onChange } = this.props;
        this.setState({ selectedOption });
        if (onChange) {
            onChange(selectedOption.value);
        }
    }

    render() {
        const { selectedOption } = this.state;
        const { theme, placeholder, selectId } = this.props;

        return (
            <SelectWrapper id={`${selectId}-select`}>
                <Select
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={this.parseOptions()}
                    placeholder={placeholder}
                    components={{ Option }}
                    aria-label={selectId}
                    inputId={selectId}
                    styles={{
                        valueContainer: (base) => ({
                            ...base,
                            padding: 0,
                        }),
                        control: (base) => ({
                            ...base,
                            border: 'none',
                            boxShadow: 'none',
                            background: 'transparent',
                        }),
                        placeholder: (defaultStyles) => {
                            return {
                                ...defaultStyles,
                                color: theme.white,
                                fontSize: 16,
                            };
                        },
                        option: (provided) => ({
                            ...provided,
                            color: theme.black,
                            fontSize: 16,
                        }),
                    }}
                    theme={(select_theme) => ({
                        ...select_theme,
                        borderRadius: 0,
                        backgroundColor: theme.black,
                        colors: {
                            ...select_theme.colors,
                            primary25: theme.dropdownHover,
                            primary: theme.paragraphMedium,
                            primary50: theme.dropdownHover,
                        },
                    })}
                />
            </SelectWrapper>
        );
    }
}

export default withTheme(SearchBarDropdown);

const CustomOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Col = styled.div`
    flex-direction: column;
`;

const SubLabelWrapper = styled.div`
    font-size: 12.8px;
    color: ${(props) => props.theme.checkboxSecondary};
`;

const CheckboxWrapper = styled.div`
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: ${(props) => props.theme.primaryLight};
    }

    .MuiIconButton-colorSecondary {
        color: ${(props) => props.theme.checkboxSecondary};
    }
`;

const SelectWrapper = styled.div`
    .css-yk16xz-control {
        border: none;
    }

    .css-tlfecz-indicatorContainer {
        display: none;
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    .css-6q0nyr-Svg {
        display: none;
    }

    .css-1uccc91-singleValue {
        font-size: 16px;
        color: ${(props) => props.theme.searchText};
    }

    .css-i0syzg-menu {
        margin-top: 20px;
    }
`;
