import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Router from 'next/router';
import styled from 'styled-components';
import InstagramIcon from '@material-ui/icons/Instagram';

import { eventService } from '../../../services/homii-services';

const ConnectWithUsMenuComponent = () => {
    const onFacebookClick = () => {
        eventService.logCustomEvent('facebook_click_navbar', {
            description: 'Clicked on Facebook from navbar',
        });
        window.open('//www.facebook.com/Homiilifestyle/', '_blank');
    };

    const onInstagramClick = () => {
        eventService.logCustomEvent('instagram_click_navbar', {
            description: 'Clicked on Instragram from navbar',
        });
        window.open('//www.instagram.com/homiilifestyle/?hl=en', '_blank');
    };

    const onContactUsClick = () => {
        Router.push('/connect-with-us');
    };

    const onFAQClick = () => {
        Router.push('/faq').then(() => window.scrollTo(0, 0));
    };

    const onBlogClick = () => {
        Router.push('/blog').then(() => window.scrollTo(0, 0));
    };

    return (
        <>
            <Grid item md={6} lg={3}>
                <StyledHeader5 marginBottom={15}>Important Stuff</StyledHeader5>
                <List>
                    <ListItemText
                        button
                        className="menu-list-item"
                        id="menu-link-blog"
                        onClick={onBlogClick}
                    >
                        <ListItemText primary="Blog" className="menu-link" />
                    </ListItemText>
                    <ListItem
                        button
                        className="menu-list-item"
                        id="menu-link-contact-us"
                        onClick={onContactUsClick}
                    >
                        <ListItemText primary="Contact Us" className="menu-link" />
                    </ListItem>
                    <ListItemText
                        button
                        className="menu-list-item"
                        id="menu-link-faq"
                        onClick={onFAQClick}
                    >
                        <ListItemText primary="FAQ" className="menu-link" />
                    </ListItemText>
                </List>
            </Grid>
            <Grid item md={6} lg={3}>
                <StyledHeader5 marginBottom={15}>Social</StyledHeader5>
                <IconButton
                    className="socials-button"
                    id="menu-link-facebook"
                    onClick={onFacebookClick}
                >
                    <img
                        src="/assets/images/facebook_icon.png"
                        alt="Facebook"
                        className="socials-image"
                    />
                </IconButton>
                <IconButton
                    className="socials-button"
                    id="menu-link-instagram"
                    onClick={onInstagramClick}
                >
                    <StyledInstagramIcon />
                </IconButton>
            </Grid>
        </>
    );
};

export default ConnectWithUsMenuComponent;

const StyledHeader5 = styled.h5`
    margin-bottom: ${(props) => props.marginBottom}px;
`;

const StyledInstagramIcon = styled(InstagramIcon)`
    color: white;
`;
