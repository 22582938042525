import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { MenuItem, Link } from '@material-ui/core';
import styled from 'styled-components';
import { ExpandMore } from '@material-ui/icons';
import classNames from 'classnames';

import PropTypes from 'prop-types';

export default function Dropdown(props) {
    const {
        placeholder,
        onChange,
        options,
        defaultValue,
        defaultId,
        error,
        helperText,
        name,
        isLink,
        variant,
        selectId,
    } = props;
    const [value, setValue] = useState(defaultValue || '');
    const [id, setId] = useState(defaultId);
    const [iconClass, setIconClass] = useState(null);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        } else {
            setValue('');
        }
        setId(selectId);
    }, [defaultValue]);

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event.target.value, event);
        }
    };

    const onDropdownOpen = () => {
        setIconClass('flip-icon');
    };

    const onDropdownClose = () => {
        setIconClass(null);
    };

    const renderLinkItem = (label, val) => {
        if (isLink) {
            return (
                <StyledMenuItem key={label} value={val}>
                    <Link href={`#${val}`} to={`#${val}`} passHref>
                        <StyledAnchor>
                            <StyledMenuItem key={label} value={val}>
                                {label}
                            </StyledMenuItem>
                        </StyledAnchor>
                    </Link>
                </StyledMenuItem>
            );
        }
        return (
            <MenuItem key={label} value={val}>
                {label}
            </MenuItem>
        );
    };

    return (
        <StyledTextField
            id={id}
            select
            label={placeholder}
            value={value}
            onChange={handleChange}
            variant={variant || 'outlined'}
            color="secondary"
            name={name}
            error={error}
            helperText={helperText}
            SelectProps={{
                // eslint-disable-next-line react/display-name
                IconComponent: ({ className }) => (
                    <ExpandMore className={classNames(className, iconClass)} />
                ),
                MenuProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                },
                onOpen: () => onDropdownOpen(),
                onClose: () => onDropdownClose(),
            }}
        >
            {options && options.map((option) => renderLinkItem(option.label, option.value))}
        </StyledTextField>
    );
}

Dropdown.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
    defaultValue: PropTypes.string,
    defaultId: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    name: PropTypes.string,
    isLink: PropTypes.bool,
    variant: PropTypes.string,
    selectId: PropTypes.string,
    className: PropTypes.object,

    onChange: PropTypes.func,
};

const StyledTextField = styled(TextField)`
    .flip-icon {
        transform: rotate(180deg);
    }
    .MuiButtonBase-root {
        font-weight: bold;
        font-size: 14px;
    }

    width: 100%;
`;

const StyledMenuItem = styled(MenuItem)`
    height: 50px;

    display: flex;
    align-items: center;

    .MuiMenuItem-root {
        font-size: 11.7px !important;
    }
`;

const StyledAnchor = styled.a`
    font-size: 11.7px;
    line-height: 140%;
    color: inherit;
    text-decoration: none;
    text-decoration-color: transparent;

    display: flex;
    align-items: center;
    width: 1000px;
`;
