import React, { useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import Image from './image.component';

function debounce(func, wait = 5, immediate = true) {
    let timeout;
    return function delay(...args) {
        const context = this;
        const later = function delayLater() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

const calc = (offset, speed) => {
    const interp = offset.interpolate((o) => `translateY(${o * speed}px)`);
    return interp;
};

const Shapes = (props) => {
    const {
        containerMargins,
        speed,
        isCircle,
        isSmallDark,
        isLargeDark,
        isDark,
        isLight,
        isOpposite,
        isDots,
        isSquare,
        smallRadius,
        largeRadius,
        isPair,
        isSingle,
        distanceBetween,
        largeMargins,
        smallMargins,
        degrees,
        zIndex,
    } = props;

    const [{ offset }, set] = useSpring(() => ({ offset: 0 }));
    const ref = useRef();
    const handleScroll = () => {
        if (_.get(ref, 'current')) {
            const posY = ref.current.getBoundingClientRect().top;
            const offsetY = window.pageYOffset - posY;
            set({ offset: offsetY });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', debounce(handleScroll), true);
        return () => {
            window.removeEventListener('scroll', debounce(handleScroll), true);
        };
    }, []);

    const renderDotsContainer = () => {
        return (
            <>
                <DotsContainer
                    ref={ref}
                    left={containerMargins.left}
                    top={containerMargins.top}
                    zIndex={zIndex}
                >
                    <animated.div style={{ transform: calc(offset, speed) }}>
                        {renderDotsShapes()}
                    </animated.div>
                </DotsContainer>
            </>
        );
    };

    const renderDotsShapes = () => {
        if (isDots) {
            if (isLight) {
                return (
                    <HeroDottedBackground
                        src="/assets/images/dotted_background_light.png"
                        alt="Dotted Background Light"
                        width={smallMargins.width}
                        height={smallMargins.height}
                        offset={offset}
                        speed={speed}
                    />
                );
            }
            if (isSquare) {
                return (
                    <HeroDottedBackground
                        src="/assets/images/dotted_background_square.png"
                        alt="Dotted Background Square"
                        width={smallMargins.width}
                        height={smallMargins.height}
                    />
                );
            }

            return (
                <HeroDottedBackground
                    src="/assets/images/dotted_background.png"
                    alt="Dotted Background Dark"
                    width={smallMargins.width}
                    height={smallMargins.height}
                />
            );
        }
        return null;
    };

    const renderOuterContainer = () => {
        if (isPair) {
            return (
                <>
                    <SliceContainer
                        ref={ref}
                        left={containerMargins.left}
                        top={containerMargins.top}
                        zIndex={zIndex}
                    >
                        <animated.div style={{ transform: calc(offset, speed) }}>
                            <TransformContainer degrees={degrees}>
                                {renderInnerContainers()}
                            </TransformContainer>
                        </animated.div>
                    </SliceContainer>
                </>
            );
        }

        if (isSingle) {
            return (
                <>
                    <SliceContainer
                        ref={ref}
                        left={containerMargins.left}
                        top={containerMargins.top}
                        zIndex={zIndex}
                    >
                        <animated.div style={{ transform: calc(offset, speed) }}>
                            <TransformContainer degrees={degrees}>
                                {renderInnerContainer()}
                            </TransformContainer>
                        </animated.div>
                    </SliceContainer>
                </>
            );
        }

        return null;
    };

    const renderInnerContainer = () => {
        return (
            <>
                <div>{renderShape(isDark || isLargeDark, true)}</div>
            </>
        );
    };

    const renderInnerContainers = () => {
        if (isOpposite) {
            return (
                <>
                    <Largediv
                        width={distanceBetween.width}
                        height={distanceBetween.height}
                        flipped={isOpposite}
                        circle={isCircle}
                    >
                        {renderShape(isDark || isLargeDark, true)}
                    </Largediv>
                    <Smalldiv float="left" circle={isCircle}>
                        {renderShape(isDark || isSmallDark)}
                    </Smalldiv>
                </>
            );
        }
        return (
            <>
                <Smalldiv float="right" circle={isCircle}>
                    {renderShape(isDark || isSmallDark)}
                </Smalldiv>
                <Largediv
                    width={distanceBetween.width}
                    height={distanceBetween.height}
                    flipped={isOpposite}
                    circle={isCircle}
                >
                    {renderShape(isDark || isLargeDark, true)}
                </Largediv>
            </>
        );
    };

    const renderShape = (isShapeDark, isLarge) => {
        let radius;
        let height;
        let width;

        if (!isLarge) {
            if (isCircle) {
                radius = smallRadius;
            } else {
                height = smallMargins.height;
                width = smallMargins.width;
            }
        } else if (isCircle) {
            radius = largeRadius;
        } else {
            height = largeMargins.height;
            width = largeMargins.width;
        }

        if (isShapeDark && isCircle) {
            return (
                <Image
                    src="/assets/images/full_circle_dark.png"
                    alt="Dark Circle"
                    width={radius}
                    height={radius}
                />
            );
        }
        if (!isShapeDark && isCircle) {
            return (
                <img
                    src="/assets/images/full_circle_light.png"
                    alt="Light Circle"
                    width={radius}
                    height={radius}
                />
            );
        }
        if (isShapeDark && !isCircle) {
            return (
                <Image
                    src="/assets/images/half_circle_dark.png"
                    alt="Half Circle Dark"
                    width={width}
                    height={height}
                />
            );
        }
        if (!isShapeDark && !isCircle) {
            return (
                <Image
                    src="/assets/images/half_circle_light.png"
                    alt="Half Circle Light"
                    width={width}
                    height={height}
                />
            );
        }
        return null;
    };

    return (
        <>
            {renderDotsContainer()}
            {renderOuterContainer()}
        </>
    );
};

export default Shapes;

Shapes.propTypes = {
    containerMargins: PropTypes.object,
    speed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isCircle: PropTypes.bool,
    isSmallDark: PropTypes.bool,
    isLargeDark: PropTypes.bool,
    isDark: PropTypes.bool,
    isLight: PropTypes.bool,
    isOpposite: PropTypes.bool,
    isDots: PropTypes.bool,
    isSquare: PropTypes.bool,
    smallRadius: PropTypes.number,
    largeRadius: PropTypes.number,
    smallMargins: PropTypes.object,
    largeMargins: PropTypes.object,
    isPair: PropTypes.bool,
    isSingle: PropTypes.bool,
    distanceBetween: PropTypes.object,
    degrees: PropTypes.string,
    zIndex: PropTypes.number,
};

const HeroDottedBackground = styled.img`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

const DotsContainer = styled.div`
    position: absolute;
    display: block;
    z-index: ${(props) => (props.zIndex ? `${props.zIndex}` : '-1')};
    left: ${(props) => props.left};
    top: ${(props) => props.top};
`;

const TransformContainer = styled.div`
    transform: ${(props) => (props.degrees ? `rotate(${props.degrees})` : null)};
`;

const SliceContainer = styled.div`
    position: absolute;
    display: block;
    left: ${(props) => props.left};
    top: ${(props) => props.top};
    z-index: ${(props) => (props.zIndex ? `${props.zIndex}` : '-1')};
`;

const Smalldiv = styled.div`
    display: ${(props) => (props.circle ? 'block' : 'flex')};
    float: ${(props) => (props.circle ? props.float : 'null')};
    justify-content: center;
    align-content: center;
`;

const Largediv = styled.div`
    display: ${(props) => (props.circle ? 'block' : 'flex')};
    float: ${(props) => (props.flipped ? 'left' : 'right')};
    margin-right: ${(props) => props.width};
    margin-top: ${(props) => props.height};
    margin-left: ${(props) => (props.flipped ? props.width : 'null')};
    margin-bottom: ${(props) => (props.flipped ? props.height : 'null')};
`;
