/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { AppBar, Toolbar, Link } from '@material-ui/core';
import Router from 'next/router';

import { Button, Image } from '../common-components';

const PortfolioNavbar = () => {
    const onBookNowClick = () => {
        Router.push('/bookings').then(() => window.scrollTo(0, 0));
    };

    return (
        <StyledAppBar>
            <Toolbar>
                <Link href="/" id="homii-logo-link" aria-label="HOMii Home">
                    <Image
                        src="/assets/images/homii_logo.png"
                        alt="HOMii Logo"
                        width={82.25}
                        height={22}
                    />
                </Link>
                <Spacer />
                <Button id="book-now-button" onClick={onBookNowClick}>
                    Book Now
                </Button>
            </Toolbar>
        </StyledAppBar>
    );
};

export default PortfolioNavbar;

const StyledAppBar = styled(AppBar)`
    background-color: transparent;
    position: relative;
    z-index: 10px;
    box-shadow: none;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;
