import getConfig from 'next/config';
import _ from 'lodash';
import axios from 'axios';

const { publicRuntimeConfig } = getConfig();
const word_press_url = publicRuntimeConfig.HOMII_WORDPRESS_URL;

async function RetrieveWordPressPage(page_name) {
    const response = await axios.get(`${word_press_url}/home_page`, {
        params: {
            slug: `${page_name}`,
            _fields: 'acf',
            acf_format: 'standard',
        },
    });
    return _.get(response, 'data[0]');
}

async function RetrieveWordPressPost(slug) {
    const response = await axios.get(`${word_press_url}/blog_posts`, {
        params: {
            _fields: 'acf',
            acf_format: 'standard',
            slug: `${slug}`,
        },
    });
    return _.get(response, 'data[0]');
}

async function ListWordPressBlogTags() {
    const response = await axios.get(`${word_press_url}/tags`);
    return _.get(response, 'data');
}

async function ListWordPressBlogPosts(tag_id, page_size, page) {
    const params =
        page !== null
            ? {
                  tags: tag_id,
                  per_page: page_size,
                  _fields: 'acf',
                  acf_format: 'standard',
                  slug: `${page}`,
              }
            : {
                  tags: tag_id,
                  per_page: page_size,
                  _fields: 'acf',
                  acf_format: 'standard',
              };

    const response = await axios.get(`${word_press_url}/blog_posts`, {
        params: params,
    });

    const data = {
        blogs: _.get(response, 'data'),
        meta: {
            next_page: 2,
            previous_page: null,
            count: parseInt(response.headers['x-wp-total']),
        },
    };

    return data;
}

export default {
    RetrieveWordPressPage,
    RetrieveWordPressPost,
    ListWordPressBlogTags,
    ListWordPressBlogPosts,
};
