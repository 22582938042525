import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Menu, MenuItem } from '@material-ui/core';
import { Phone } from '@material-ui/icons';
import getConfig from 'next/config';

import { eventService } from '../../services/homii-services';

const NavbarChatMenu = ({ anchorEl, setAnchorEl }) => {
    const { publicRuntimeConfig } = getConfig();
    const isMenuOpen = Boolean(anchorEl);

    const LiveChatMenuItem = () => {
        if (!publicRuntimeConfig.liveChatEnabled) {
            return null;
        }

        return (
            <StyledMenuItem onClick={handleMenuClose} id="live-menu-item">
                <StyledImage src="/assets/images/chat_bubble_icon.png" alt="Chat Bubble" />
                Open in LiveChat
            </StyledMenuItem>
        );
    };

    const handleMenuClose = (type) => {
        if (type === 'whatsapp') {
            eventService.logCustomEvent('whatsapp_contact_us', {
                description: 'Clicked on whatsapp from Contact Us page',
            });
        } else if (type === 'phone') {
            eventService.logCustomEvent('click_to_call_contact_us', {
                description: 'Clicked on phone  from Contact Us page',
            });
        }

        setAnchorEl(null);
    };

    return (
        <Menu
            elevation={0}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id="primary-search-account-menu"
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{
                style: {
                    boxShadow: '0px 13px 52px rgba(0, 0, 0, 0.1)',
                    borderRadius: 5,
                    marginTop: 60,
                },
            }}
        >
            <StyledMenuItem
                onClick={() => handleMenuClose('whatsapp')}
                id="whatsapp-menu-item"
                component="a"
                href="https://api.whatsapp.com/send?phone=27662818767"
                target="_blank"
                rel="noreferrer"
            >
                <StyledImage src="/assets/images/whatsapp_icon.png" alt="WhatsApp" />
                Open in WhatsApp
            </StyledMenuItem>
            <StyledMenuItem
                onClick={() => handleMenuClose('phone')}
                id="office-phone-menu-item"
                component="a"
                href="tel:031 327 6400"
            >
                <StyledPhone fontSize="small" />
                031 327 6400
            </StyledMenuItem>
            <LiveChatMenuItem />
        </Menu>
    );
};

export default NavbarChatMenu;

NavbarChatMenu.propTypes = {
    cities: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    anchorEl: PropTypes.bool,

    setAnchorEl: PropTypes.func,
};

const StyledPhone = styled(Phone)`
    margin-right: 8px;
    color: ${(props) => props.theme.white};
`;

const StyledMenuItem = styled(MenuItem)`
    margin: 5px;
    margin-top: 10px;
`;

const StyledImage = styled.img`
    width: 18px;
    margin-right: 10px;
`;
