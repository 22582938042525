import React from 'react';
import {
    Button as MaterialButton,
    CircularProgress as MaterialCircularProgress,
    Link,
} from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../../../utils/theme';

const Button = (props) => {
    const {
        type,
        children,
        startIcon,
        setTextDecoration,
        isLoading,
        disabled,
        linkColor,
        ...rest
    } = props;

    if (type === 'secondary') {
        return (
            <SecondaryButton
                {...rest}
                variant="outlined"
                color={isLoading ? theme.loadingSecondaryButton : 'default'}
                startIcon={startIcon && startIcon}
                disabled={isLoading || disabled}
            >
                {children}
                {isLoading && <CircularProgress />}
            </SecondaryButton>
        );
    }

    if (type === 'link') {
        return (
            <LinkButton $linkColor={linkColor} $setTextDecoration={setTextDecoration} {...rest}>
                {children}
            </LinkButton>
        );
    }

    return (
        <PrimaryButton
            {...rest}
            variant="contained"
            color={isLoading ? 'inherit' : 'default'}
            disabled={isLoading || disabled}
            startIcon={startIcon && startIcon}
        >
            {children}
            {isLoading && <CircularProgress size={25} />}
        </PrimaryButton>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    startIcon: PropTypes.node,
    options: PropTypes.array,
    badgeCount: PropTypes.number,
    color: PropTypes.string,
    setTextDecoration: PropTypes.bool,
    linkColor: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Button;

const SecondaryButton = styled(MaterialButton)`
    background-color: ${(props) =>
        props.color !== 'default' ? props.color : props.theme.transparent};
    border: 1px solid ${(props) => props.theme.carouselButtonBorder};
    border-radius: 3px;
    box-shadow: none;
    font-size: 16px;
    padding: 12px 17px;
    color: ${(props) => props.theme.searchText};
    min-width: 136px;
`;

const PrimaryButton = styled(MaterialButton)`
    color: white;
    background-color: ${(props) =>
        props.color !== 'default' ? props.theme.loadingPrimaryButton : props.theme.primaryLight};
    box-shadow: none;
    border-radius: 3px;
    padding: 12px 17px;
    font-size: 16px;
    min-width: 136px;
    &:hover {
        background-color: ${(props) => props.theme.loadingPrimaryButton};
        box-shadow: 0px 7px 34px rgba(49, 114, 97, 0.1021);
    }
`;

const CircularProgress = styled(MaterialCircularProgress)`
    position: absolute;
    color: white;
`;

const LinkButton = styled(Link)`
    color: ${(props) => (props.color ? props.color : 'var(--primary-color)')};
    cursor: pointer;
    font-weight: bold;
    text-decoration: ${({ $setTextDecoration }) => ($setTextDecoration ? 'underline' : 'none')};
    color: ${({ $linkColor }) => $linkColor && $linkColor};
`;
