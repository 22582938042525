import ipGeolocationServiceUrls from './geolocation-service-urls';

async function getGeolocationDetails() {
    const url = ipGeolocationServiceUrls.getGeolocationDetailsUrl();
    const response = await fetch(url);
    return await response.json().catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
    });
}

async function getGeolocationDetailsFallback() {
    const url = ipGeolocationServiceUrls.getGeolocationDetailsFallbackUrl();
    const response = await fetch(url);
    const geoLocationData = await response.json().catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
    });
    return geoLocationData;
}
export default {
    getGeolocationDetails,
    getGeolocationDetailsFallback,
};
