import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import accountReducer from '../common-reducers/account-reducer/account.reducer';
import cityReducer from '../common-reducers/city-reducer/city.reducer';
import buildingReducer from '../common-reducers/buildings-reducer/building.reducer';
import paymentReducer from '../common-reducers/payment-reducer/payment.reducer';
import preBookingReducer from '../common-reducers/pre-booking-reducer/pre-booking.reducer';
import roomTypesReducer from '../common-reducers/room-types-reducer/room-types.reducer';
import filterReducer from '../common-reducers/filter-reducer/filter.reducer';
import geolocationReducer from '../common-reducers/geolocation-reducer/geolocation.reducer';
import portfolioReducer from '../common-reducers/portfolio-reducer/portfolio.reducer';
import agentReducer from '../common-reducers/agent-reducer/agent.reducer';
import marketingReducer from '../common-reducers/marketing-reducer/marketing.reducer';

const combinedReducers = combineReducers({
    accountReducer,
    cityReducer,
    buildingReducer,
    paymentReducer,
    preBookingReducer,
    roomTypesReducer,
    filterReducer,
    geolocationReducer,
    portfolioReducer,
    agentReducer,
    marketingReducer,
});

const RootReducer = (state, action) => {
    if (action.type === HYDRATE) {
        return {
            ...state,
            ...action.payload,
        };
    }
    return combinedReducers(state, action);
};
export default RootReducer;
