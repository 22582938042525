/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import _ from 'lodash';
import PropTypes from 'prop-types';

import useResponsive from '../../helpers/responsive';
import Image from './image.component';
import { WordPressTransformHeight } from '../../helpers/wordpress-image-transformer';

const calc = (offset, speed, shouldTransform) => {
    if (!shouldTransform || !offset) {
        return offset;
    }
    return offset.interpolate((o) => `translateY(${o * speed}px)`);
};

const ParallaxComponent = ({ src, alt, style, speed, max, min, blog }) => {
    const ref = useRef();
    const { isMobile, isTablet } = useResponsive();
    const [{ offset }, set] = useSpring(() => ({ offset: 0 }));
    const [shouldTransform, setShouldTransform] = useState(true);
    let debouncedScroll;

    const handleScroll = () => {
        const posY = ref.current && ref.current.getBoundingClientRect().top;
        const offsetY = window.pageYOffset - posY;
        if (_.isNil(max) && _.isNil(min)) {
            setShouldTransform(true);
            set({ offset: offsetY });
        } else if (!_.isNil(max) && _.isNil(min)) {
            if (offsetY < max) {
                setShouldTransform(true);
                set({ offset: offsetY });
            } else {
                setShouldTransform(false);
            }
        } else if (!_.isNil(min) && _.isNil(max)) {
            if (offsetY < min) {
                setShouldTransform(true);
                set({ offset: offsetY });
            } else {
                setShouldTransform(false);
            }
        }
    };

    useEffect(() => {
        debouncedScroll = _.debounce(handleScroll, 1);
        window.addEventListener('scroll', debouncedScroll, true);
        return () => {
            window.removeEventListener('scroll', debouncedScroll, true);
        };
    }, []);

    if (blog) {
        const featuredImage = WordPressTransformHeight(src, 950);
        return (
            <div ref={ref}>
                <animated.div
                    style={{
                        transform: calc(offset, shouldTransform ? speed : 0, shouldTransform),
                    }}
                >
                    <Image
                        src={featuredImage}
                        alt={alt}
                        width="100%"
                        height={isMobile ? 400 : isTablet ? 600 : 950}
                        className="card-image"
                        isOldImage
                    />
                </animated.div>
            </div>
        );
    }

    return (
        <div ref={ref} style={style}>
            <animated.div
                style={{ transform: calc(offset, shouldTransform ? speed : 0, shouldTransform) }}
            >
                <Image src={src} alt="parallax-image" isOldImage />
            </animated.div>
        </div>
    );
};

export default ParallaxComponent;

ParallaxComponent.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    style: PropTypes.object,
    speed: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    blog: PropTypes.bool,
};
