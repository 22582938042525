const contactUsBaseUrl = '/ContactUs';
const websiteMarketingBaseUrl = '/website/marketing';

export default {
    generalInquiryUrl: () => `${contactUsBaseUrl}/generalInquiry`,
    bookViewingUrl: () => `${contactUsBaseUrl}/bookViewing`,
    joinNewsletterUrl: () => `${contactUsBaseUrl}/joinNewsletter`,
    registerOClubUrl: () => `${contactUsBaseUrl}/registerOClub`,
    commercialRentalUrl: () => `${contactUsBaseUrl}/commercialRental`,
    investingUrl: () => `${contactUsBaseUrl}/investing`,
    partnersAndLandlordsUrl: () => `${contactUsBaseUrl}/partnersAndLandlords`,
    corporateBookingsUrl: () => `${contactUsBaseUrl}/corporateBookings`,
    leadFromMarketingCampaignUrl: () => `${contactUsBaseUrl}/leadFromMarketingCampaign`,
    leadFromSalesPersonUrl: () => `${contactUsBaseUrl}/leadFromSalesPerson`,
    marketingCampaignDataUrl: () => `${contactUsBaseUrl}/leadFromSalesPerson`,
    getMarketingCampaignDataByNameUrl: (campaignName) =>
        `${websiteMarketingBaseUrl}/marketingCampaign/${campaignName}`,
};
