const cityBaseUrl = '/City';

export default {
    getAllCitiesUrl: () => `${cityBaseUrl}/all`,
    getCityUrl: (cityId) => `${cityBaseUrl}?CityId=${cityId}`,
    getClosestCityUrl: (latitude, longitude, portfolioId) => {
        return portfolioId
            ? `${cityBaseUrl}/closest?Latitude=${latitude}&Longitude=${longitude}&PortfolioId=${portfolioId}`
            : `${cityBaseUrl}/closest?Latitude=${latitude}&Longitude=${longitude}`;
    },
};
