import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const isProd = process.env.NODE_ENV === 'production';
const ipWhoisBaseUrl = isProd ? 'https://ipwhois.pro/json' : 'https://ipwhois.app/json/';
const geolocationDbUrl = 'https://geolocation-db.com/json';

export default {
    getGeolocationDetailsUrl: () =>
        `${ipWhoisBaseUrl}/?key=${publicRuntimeConfig.HOMII_IPWHOIS_KEY}`,
    getGeolocationDetailsFallbackUrl: () => `${geolocationDbUrl}`,
};
