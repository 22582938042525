import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

const NumberSpinner = (props) => {
    const { max, min, value, onChange, type } = props;
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        if (value === 'Any') {
            setInputValue(0);
        } else {
            setInputValue(parseInt(value, 10));
        }
    }, [value]);

    useEffect(() => {
        if (!_.isNil(onChange)) {
            onChange(inputValue);
        }
    }, [inputValue]);

    const decrease = () => {
        setInputValue(inputValue - 1);
    };

    const increase = () => {
        setInputValue(inputValue + 1);
    };

    const renderInput = () => {
        if (type === 'filter') {
            if (inputValue === 0) {
                return <InputNumber>Any</InputNumber>;
            }
            return <InputNumber>{`${inputValue}+`}</InputNumber>;
        }
        return <InputNumber>{inputValue}</InputNumber>;
    };

    return (
        <Container>
            <RoundedButton
                variant="outlined"
                disabled={(!!min && inputValue <= min) || inputValue === 0}
                onClick={decrease}
            >
                -
            </RoundedButton>

            {renderInput()}
            <RoundedButton
                variant="outlined"
                disabled={!!max && inputValue >= max}
                onClick={increase}
            >
                +
            </RoundedButton>
        </Container>
    );
};

export default NumberSpinner;

NumberSpinner.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,

    onChange: PropTypes.func,
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const InputNumber = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    padding: 24px;
`;

const RoundedButton = styled(Button)`
    border-radius: 28px;
    width: 56px;
    height: 56px;
    padding: 0;
    min-width: 0;
    max-width: none;
    border: none;
    color: var(--primary-color);
    font-size: 22px;
    background: #181d1f;

    &:hover {
        background: #38444c;
    }

    &.Mui-disabled {
        border: 1px solid rgba(255, 255, 255, 0.25);
        color: rgba(255, 255, 255, 0.25);
        background-color: transparent;
    }
`;
