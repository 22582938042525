import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'city';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setCities = new CreateAction(reducerName, 'SET_CITIES');
export const setCitiesAction = setCities.action;

const setSelectedCity = new CreateAction(reducerName, 'SET_SELECTED_CITY');
export const setSelectedCityAction = setSelectedCity.action;

const setCurrentCity = new CreateAction(reducerName, 'SET_CURRENT_CITY');
export const setCurrentCityAction = setCurrentCity.action;

const INITIAL_STATE = {
    isLoading: false,
    cities: {},
    selectedCity: null,
    currentCity: null,
};

export const citySelector = (rootReducer) => rootReducer.cityReducer;

export default function cityReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setCities.actionType:
            return { ...state, cities: action.payload };
        case setSelectedCity.actionType:
            return { ...state, selectedCity: action.payload };
        case setCurrentCity.actionType:
            return { ...state, currentCity: action.payload };
        default:
            return state;
    }
}
