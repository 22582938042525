import networkService from '../network-service/network.service';
import eftUrls from './eft-service-urls';

function getEftPaymentStatus(transactionId) {
    const url = eftUrls.paymentStatusUrl(transactionId);
    return networkService.makeGet(url);
}

export default {
    getEftPaymentStatus,
};
