import networkService from '../network-service/network.service';
import roomTypeUrls from './room-types-service-urls';

function searchAvailable(cityId, startDate, endDate) {
    const url = roomTypeUrls.searchAvailable(cityId, startDate, endDate);
    return networkService.makeGet(url);
}

function getRoomType() {
    const url = roomTypeUrls.roomTypeUrl();
    return networkService.makeGet(url);
}

function getRoomTypeOptionalExtras(roomTypeId) {
    const url = roomTypeUrls.roomTypeOptionalExtrasUrl(roomTypeId);
    return networkService.makeGet(url);
}

function getRoomTypeByCityId(cityId) {
    const url = roomTypeUrls.roomTypeByCityIdUrl(cityId);
    return networkService.makeGet(url);
}

function getRoomTypeById(roomTypeId) {
    const url = roomTypeUrls.roomTypeByIdUrl(roomTypeId);
    return networkService.makeGet(url);
}

function getRoomTypeAvailability(roomTypeId, startDate, endDate) {
    const url = roomTypeUrls.roomTypeAvailabilityUrl(roomTypeId, startDate, endDate);
    return networkService.makeGet(url);
}

function getRoomTypeByBuildingId(buildingId) {
    const url = roomTypeUrls.roomTypeByBuildingIdUrl(buildingId);
    return networkService.makeGet(url);
}

function getRoomTypeSearchAvailableByBuilding(buildingId, startDate, endDate) {
    const url = roomTypeUrls.roomTypeSearchAvailableByBuildingUrl(buildingId, startDate, endDate);
    return networkService.makeGet(url);
}

function getRoomTypeSearchAvailableByCoordinates(latitude, longitude, startDate, endDate) {
    const url = roomTypeUrls.roomTypeSearchAvailableByCoordinatesUrl(
        latitude,
        longitude,
        startDate,
        endDate,
    );

    return networkService.makeGet(url);
}

function getRoomTypeByPortofolioId(portfolioId) {
    const url = roomTypeUrls.roomTypeByPortfolioIdUrl(portfolioId);
    return networkService.makeGet(url);
}

function getRoomTypeSummaryAvailableByBuilding(buildingId) {
    const url = roomTypeUrls.roomTypeSummaryAvailableByBuildingUrl(buildingId);
    return networkService.makeGet(url);
}

export default {
    searchAvailable,
    getRoomType,
    getRoomTypeOptionalExtras,
    getRoomTypeByCityId,
    getRoomTypeById,
    getRoomTypeAvailability,
    getRoomTypeByBuildingId,
    getRoomTypeSearchAvailableByBuilding,
    getRoomTypeSearchAvailableByCoordinates,
    getRoomTypeByPortofolioId,
    getRoomTypeSummaryAvailableByBuilding,
};
