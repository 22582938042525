import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'filterReducer';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setSearchTerm = new CreateAction(reducerName, 'SET_SEARCH_TERM');
export const setSearchTermAction = setSearchTerm.action;

const setPriceRangeMinAndMax = new CreateAction(reducerName, 'SET_PRICE_RANGE_MIN_AND_MAX');
export const setPriceRangeMinAndMaxAction = setPriceRangeMinAndMax.action;

const setBadgeCount = new CreateAction(reducerName, 'SET_BADGE_COUNT');
export const setBadgeCountAction = setBadgeCount.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const setSortByPrice = new CreateAction(reducerName, 'SET_SORT_BY_PRICE');
export const setSortByPriceAction = setSortByPrice.action;

const INITIAL_STATE = {
    isLoading: false,
    searchTerm: null,
    priceRangeMinAndMax: { min: 500, max: 10000 },
    badgeCount: 0,
    sortBy: 'Sort By',
};

export default function filterReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setSearchTerm.actionType:
            return { ...state, searchTerm: action.payload };
        case setPriceRangeMinAndMax.actionType:
            return { ...state, priceRangeMinAndMax: action.payload };
        case setBadgeCount.actionType:
            return { ...state, badgeCount: action.payload };
        case setSortByPrice.actionType:
            return { ...state, sortBy: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
