import moment from 'moment';
import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'roomTypes';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setRoomTypes = new CreateAction(reducerName, 'SET_ROOMTYPES_TYPES');
export const setRoomTypesAction = setRoomTypes.action;

const setSelectedTerm = new CreateAction(reducerName, 'SET_SELECTED_TERM');
export const setSelectedTermAction = setSelectedTerm.action;

const setDailyStartDate = new CreateAction(reducerName, 'SET_DAILY_START_DATE');
export const setDailyStartDateAction = setDailyStartDate.action;

const setDailyEndDate = new CreateAction(reducerName, 'SET_DAILY_END_DATE');
export const setDailyEndDateAction = setDailyEndDate.action;

const setSelectedRoomType = new CreateAction(reducerName, 'SET_SELECTED_ROOM_TYPE');
export const setSelectedRoomTypeAction = setSelectedRoomType.action;

const setRoomContentCMS = new CreateAction(reducerName, 'SET_ROOM_CONTENT_CMS');
export const setRoomContentCMSAction = setRoomContentCMS.action;

const setSelectedBuildingRoomTypes = new CreateAction(
    reducerName,
    'SET_SELECTED_BUILDING_ROOMTYPES_ACTIONS',
);
export const setSelectedBuildingRoomTypesAction = setSelectedBuildingRoomTypes.action;

const setSelectedBuildingRoomTypesSummary = new CreateAction(
    reducerName,
    'SET_SELECTED_BUILDING_ROOMTYPES_SUMMARY_ACTIONS',
);
export const setSelectedBuildingRoomTypesSummaryAction = setSelectedBuildingRoomTypesSummary.action;

const INITIAL_STATE = {
    isLoading: false,
    roomTypes: {},
    selectedTerm: null,
    dailyStartDate: moment().format('YYYY-MM-DD'),
    dailyEndDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    selectedRoomType: null,
    roomContentCMS: null,
    selectedBuildingRoomTypes: null,
    selectedBuildingRoomTypesSummary: null,
};

export const roomTypesSelector = (rootReducer) => rootReducer.roomTypesReducer;

export default function roomTypesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setRoomTypes.actionType:
            return { ...state, roomTypes: action.payload };
        case setSelectedTerm.actionType:
            return { ...state, selectedTerm: action.payload };
        case setDailyStartDate.actionType:
            return { ...state, dailyStartDate: action.payload };
        case setDailyEndDate.actionType:
            return { ...state, dailyEndDate: action.payload };
        case setSelectedRoomType.actionType:
            return { ...state, selectedRoomType: action.payload };
        case setRoomContentCMS.actionType:
            return { ...state, roomContentCMS: action.payload };
        case setSelectedBuildingRoomTypes.actionType:
            return { ...state, selectedBuildingRoomTypes: action.payload };
        case setSelectedBuildingRoomTypesSummary.actionType:
            return { ...state, selectedBuildingRoomTypesSummary: action.payload };
        default:
            return state;
    }
}
