/* eslint-disable implicit-arrow-linebreak */
const preBookingBaseUrl = '/PreBooking';
const waitingListBaseUrl = '/WaitingList';

export default {
    preBookingQuoteUrl: () => `${preBookingBaseUrl}/quote`,
    preBookingUrl: () => `${preBookingBaseUrl}`,
    cancelPreBookingUrl: () => `${preBookingBaseUrl}/cancel`,
    preBookingStartCreditCardPayment: () => `${preBookingBaseUrl}/startCreditCardPayment`,
    preBookingStartEftPayment: () => `${preBookingBaseUrl}/startEftPayment`,
    waitingListUrl: () => `${waitingListBaseUrl}`,
    userPreBookingUrl: () => `${preBookingBaseUrl}/userPrebooking`,
};
