import networkService from '../network-service/network.service';
import creditCardUrls from './credit-card-service-urls';

function getCreditCards() {
    const url = creditCardUrls.creditCardsUrl();
    return networkService.makeGet(url);
}

function getCreditCardPaymentStatus(transactionId) {
    const url = creditCardUrls.paymentStatusUrl(transactionId);
    return networkService.makeGet(url);
}

export default {
    getCreditCards,
    getCreditCardPaymentStatus,
};
