import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'preBooking';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setPreBookingQuote = new CreateAction(reducerName, 'SET_PRE_BOOKING_QUOTE');
export const setPreBookingQuoteAction = setPreBookingQuote.action;

const setPreBookingId = new CreateAction(reducerName, 'SET_PRE_BOOKING_ID');
export const setPreBookingIdAction = setPreBookingId.action;

const setGender = new CreateAction(reducerName, 'SET_GENDER');
export const setGenderAction = setGender.action;

const setStartDate = new CreateAction(reducerName, 'SET_START_DATE');
export const setStartDateAction = setStartDate.action;

const setEndDate = new CreateAction(reducerName, 'SET_END_DATE');
export const setEndDateAction = setEndDate.action;

const setNumberOfOccupants = new CreateAction(reducerName, 'SET_NUMBER_OF_OCCUPANTS');
export const setNumberOfOccupantsAction = setNumberOfOccupants.action;

const setPreBookingIdTimestamp = new CreateAction(reducerName, 'SET_PRE_BOOKING_ID_TIMESTAMP');
export const setPreBookingIdTimestampAction = setPreBookingIdTimestamp.action;

const setHasAcceptedTermsAndCondtitions = new CreateAction(
    reducerName,
    'SET_HAS_ACCEPTED_TERMS_AND_CONDITIONS',
);
export const setHasAcceptedTermsAndCondtitionsAction = setHasAcceptedTermsAndCondtitions.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    preBookingQuote: null,
    preBookingId: null,
    gender: null,
    startDate: null,
    endDate: null,
    numberOfOccupants: null,
    preBookingIdTimestamp: null,
    hasAcceptedTermsAndConditions: null,
};

export default function preBookingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setPreBookingQuote.actionType:
            return { ...state, preBookingQuote: action.payload };
        case setPreBookingId.actionType:
            return { ...state, preBookingId: action.payload };
        case setGender.actionType:
            return { ...state, gender: action.payload };
        case setStartDate.actionType:
            return { ...state, startDate: action.payload };
        case setEndDate.actionType:
            return { ...state, endDate: action.payload };
        case setNumberOfOccupants.actionType:
            return { ...state, numberOfOccupants: action.payload };
        case setPreBookingIdTimestamp.actionType:
            return { ...state, preBookingIdTimestamp: action.payload };
        case setHasAcceptedTermsAndCondtitions.actionType:
            return { ...state, hasAcceptedTermsAndConditions: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
