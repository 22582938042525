import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Router from 'next/router';
import styled from 'styled-components';

const FindASpaceMenuComponent = () => {
    const onBrowseByCityClick = () => {
        Router.push('/play/own-your-city');
    };

    const onBrowseByBuildingsClick = () => {
        Router.push('/buildings');
    };

    const onStayOrStyleClick = () => {
        Router.push('/find-a-space#stay_or_style_section');
    };

    const onSpaceClick = () => {
        Router.push('/find-a-space#space_section');
    };

    return (
        <Grid item md={12} lg={6}>
            <StyledHeader5 marginBottom={15}>Find a Space</StyledHeader5>
            <List>
                <ListItem
                    button
                    className="menu-list-item"
                    id="menu-link-browse-by-city"
                    onClick={onBrowseByCityClick}
                >
                    <ListItemText primary="Browse by City" className="menu-link" />
                </ListItem>
                <ListItem
                    button
                    className="menu-list-item"
                    id="menu-link-explore-buildings"
                    onClick={onBrowseByBuildingsClick}
                >
                    <ListItemText primary="Explore Buildings" className="menu-link" />
                </ListItem>
                <ListItem
                    button
                    className="menu-list-item"
                    id="menu-link-stay"
                    onClick={onStayOrStyleClick}
                >
                    <ListItemText primary="Stay" className="menu-link" />
                </ListItem>
                <ListItem
                    button
                    className="menu-list-item"
                    id="menu-link-style"
                    onClick={onStayOrStyleClick}
                >
                    <ListItemText primary="Style" className="menu-link" />
                </ListItem>
                <ListItem
                    button
                    className="menu-list-item"
                    id="menu-link-space"
                    onClick={onSpaceClick}
                >
                    <ListItemText primary="Space" className="menu-link" />
                </ListItem>
            </List>
        </Grid>
    );
};

export default FindASpaceMenuComponent;

const StyledHeader5 = styled.h5`
    margin-bottom: ${(props) => props.marginBottom}px;
`;
