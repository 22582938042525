import React from 'react';
import PropTypes from 'prop-types';

const MenuImageComponent = (props) => {
    const { imageUrl, title, subtitle } = props;
    return (
        <div>
            <img src={imageUrl} alt="menu" className="menu-image" />
            <p className="menu-image-title">{title}</p>
            <p className="menu-image-subtitle">{subtitle}</p>
        </div>
    );
};

MenuImageComponent.propTypes = {
    imageUrl: PropTypes.any,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export default MenuImageComponent;
