/* eslint-disable no-param-reassign */
import _ from 'lodash';

function setItem(key, value) {
    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }
    return sessionStorage.setItem(key, value);
}

function getItem(key) {
    if (!_.isNil(sessionStorage.getItem(key))) {
        const storageObjectString = sessionStorage.getItem(key);
        try {
            return JSON.parse(storageObjectString);
        } catch (e) {
            return storageObjectString;
        }
    }
    return null;
}

function removeItem(key) {
    return sessionStorage.removeItem(key);
}

function clear() {
    return sessionStorage.clear();
}

export default {
    setItem,
    getItem,
    removeItem,
    clear,
};
