import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Menu as MenuIcon, Close, Facebook, Instagram, ExpandMore, Chat } from '@material-ui/icons';
import {
    Drawer,
    Accordion,
    AppBar,
    AccordionSummary,
    AccordionDetails,
    Toolbar,
    IconButton,
    Typography,
    InputBase,
    Link,
    Button,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import Router from 'next/router';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { eventService } from '../../services/homii-services';
import useResponsive from '../../helpers/responsive';
import { Image } from '../common-components';
import NavbarChatMenu from './navbar-chat-menu.component';
import { citySelector } from '../../common-reducers/city-reducer/city.reducer';

const MAIN_NAV_HEIGHT_MOBILE = 66;

const MobileNavbar = ({ shouldScroll, isRoomPage, isBookingsPage }) => {
    const { cities } = useSelector(citySelector);
    const [cityOptions, setCityOptions] = useState(null);
    const [chatAnchorEl, setChatAnchorEl] = useState(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const [cityName, setCityName] = useState(null);
    const { isMobile } = useResponsive();

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const handleScroll = (prevScrollPosition) => {
        const currentScrollPosition = window.pageYOffset;

        if (
            isMobile &&
            shouldScroll &&
            prevScrollPosition < currentScrollPosition &&
            currentScrollPosition > MAIN_NAV_HEIGHT_MOBILE &&
            !isScrolling
        ) {
            setIsScrolling(true);
        }
        if ((window.pageYOffset === 0 || !isMobile) && isScrolling) {
            setIsScrolling(false);
        }
    };

    useEffect(() => {
        setCityOptions(_.map(cities, (city) => city));
    }, [cities]);

    useEffect(() => {
        const prevScrollPosition = window.pageYOffset;
        window.addEventListener('scroll', () => handleScroll(prevScrollPosition));

        return () => window.removeEventListener('scroll', handleScroll);
    });

    const handleMobileMenuClose = () => {
        setIsMobileMenuOpen(false);
    };

    const handleMobileMenuOpen = (event) => {
        setIsMobileMenuOpen(Boolean(event.currentTarget));
    };

    const onSearchClick = () => {
        Router.push(`/bookings?city=${cityName}`);
        setCityName(null);
    };

    const onSearchInputChange = (event, value) => {
        const city = _.get(value, 'name');

        setCityName(null);
        _.forEach(cities, (item) => {
            const name = _.get(item, 'name');
            const formattedCity = _.upperCase(city.charAt(0)) + city.slice(1);
            if (_.lowerCase(name) === _.lowerCase(city)) {
                setCityName(formattedCity);
            }
        });
    };

    const onBrowseByCityClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/play/own-your-city');
    };

    const onBrowseByBuildingsClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/buildings');
    };

    const onStayOrStyleClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/find-a-space#stay_or_style_section');
    };

    const onSpaceClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/find-a-space#space_section');
    };

    const onContactUsClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/connect-with-us').then(() => window.scrollTo(0, 0));
    };

    const onBlogClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/blog').then(() => window.scrollTo(0, 0));
    };

    const onOwnYourCityClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/play/own-your-city').then(() => window.scrollTo(0, 0));
    };

    const onGalleryClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/play/gallery').then(() => window.scrollTo(0, 0));
    };

    const onFAQClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/faq').then(() => window.scrollTo(0, 0));
    };

    const onBookNowClick = () => {
        if (isRoomPage) {
            const element = document.getElementById('booking-quote-container');
            element.scrollIntoView();
        } else {
            Router.push('/bookings').then(() => window.scrollTo(0, 0));
        }
    };

    const onFindASpaceClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/find-a-space').then(() => window.scrollTo(0, 0));
    };

    const onOclubClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/o-club').then(() => window.scrollTo(0, 0));
    };

    const onFacebookClick = () => {
        eventService.logCustomEvent('facebook_click_navbar', {
            description: 'Clicked on Facebook from navbar',
        });
        window.open('//www.facebook.com/Homiilifestyle/', '_blank');
    };

    const onInstagramClick = () => {
        eventService.logCustomEvent('instagram_click_navbar', {
            description: 'Clicked on Instragram from navbar',
        });
        window.open('//www.instagram.com/homiilifestyle/?hl=en', '_blank');
    };

    const onAboutClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/about').then(() => window.scrollTo(0, 0));
    };

    const onLiveYourBestLifeClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/live/live-your-best-life').then(() => window.scrollTo(0, 0));
    };

    const onOurAppClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/live/our-app').then(() => window.scrollTo(0, 0));
    };

    const onYourOfficeClick = () => {
        setIsMobileMenuOpen(false);
        Router.push('/work/your-office').then(() => window.scrollTo(0, 0));
    };

    const handleChatMenuOpen = (event) => {
        setChatAnchorEl(event.currentTarget);
    };

    const renderBookNowButton = () => {
        if ((isMobile && isRoomPage) || isBookingsPage) {
            return null;
        }

        return (
            <BookNowButton
                type="button"
                variant="contained"
                id="book-now-button"
                onClick={onBookNowClick}
            >
                Book Now
            </BookNowButton>
        );
    };

    const renderSrollSearchBar = () => {
        return (
            <SearchContainer>
                <IconButton id="mobile-search-button" onClick={onSearchClick} disabled={!cityName}>
                    <SearchIcon src="/assets/images/search-icon.png" alt="search" />
                </IconButton>

                <StyledAutoComplete
                    id="combo-box-demo"
                    options={cityOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    onChange={onSearchInputChange}
                    onKeyPress={(event) => {
                        if (event.key == 'Enter') {
                            onSearchClick();
                        }
                    }}
                    renderInput={(params) => {
                        // eslint-disable-next-line no-unused-vars
                        const { InputLabelProps, InputProps, ...rest } = params;
                        return <StyledInputBase {...params.InputProps} {...rest} />;
                    }}
                />
            </SearchContainer>
        );
    };

    const renderShouldNotScroll = () => {
        return (
            <ScrollContainer scrollable>
                <ScrollContentContainer>
                    <StyledAppBar position="fixed">
                        <Toolbar>
                            <MenuButton
                                aria-label="open-mobile-drawer"
                                aria-controls={mobileMenuId}
                                aria-haspopup
                                onClick={handleMobileMenuOpen}
                                edge="start"
                                id="mobile-menu-button"
                            >
                                <StyledMenuIcon />
                            </MenuButton>
                            <Link href="/" id="homii-logo-link">
                                <Image
                                    src="/assets/images/homii_logo.png"
                                    alt="HOMii Logo"
                                    width={82.25}
                                    height={22}
                                />
                            </Link>
                            <Spacer />
                            {renderBookNowButton()}
                        </Toolbar>
                    </StyledAppBar>
                    <Toolbar />
                    {renderDrawerMenu()}
                </ScrollContentContainer>
            </ScrollContainer>
        );
    };

    const renderDrawerMenu = () => {
        return (
            <StyledDrawer id={mobileMenuId} open={isMobileMenuOpen} onClose={handleMobileMenuClose}>
                <div>
                    <IconButton
                        color="inherit"
                        onClick={handleMobileMenuClose}
                        id="mobile-menu-close"
                    >
                        <StyledClose />
                    </IconButton>
                </div>
                <StyledAccordion>
                    <AccordionSummary
                        expandIcon={<StyledExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className="drawer-heading">Find a Space</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-browse-by-city"
                                onClick={onBrowseByCityClick}
                            >
                                <StyledListItemText primary="Browse by City" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-explore-buildings"
                                onClick={onBrowseByBuildingsClick}
                            >
                                <StyledListItemText primary="Explore Buildings" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-stay"
                                onClick={onStayOrStyleClick}
                            >
                                <StyledListItemText primary="Stay" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-style"
                                onClick={onStayOrStyleClick}
                            >
                                <StyledListItemText primary="Style" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-space"
                                onClick={onSpaceClick}
                            >
                                <StyledListItemText primary="Space" />
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion>
                    <AccordionSummary
                        expandIcon={<StyledExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className="drawer-heading">Why HOMii Life?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <h5>#Live</h5>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-about-homii"
                                onClick={onAboutClick}
                            >
                                <StyledListItemText primary="About HOMii" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-welcome-to-your-best-life"
                                onClick={onLiveYourBestLifeClick}
                            >
                                <StyledListItemText primary="Welcome To Your Best Life" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-do-life-on-our-app"
                                onClick={onOurAppClick}
                            >
                                <StyledListItemText primary="Do Life On Our App" />
                            </ListItem>
                            <h5>#Work</h5>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-your-homii-office"
                                onClick={onYourOfficeClick}
                            >
                                <StyledListItemText primary="Your HOMii Office" />
                            </ListItem>
                            <h5>#Play</h5>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-own-your-city"
                                onClick={onOwnYourCityClick}
                            >
                                <StyledListItemText primary="Own Your City" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-gallery"
                                onClick={onGalleryClick}
                            >
                                <StyledListItemText primary="Gallery" />
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion>
                    <AccordionSummary
                        expandIcon={<StyledExpandMore />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className="drawer-heading">Connect With Us</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <h5>Important Stuff</h5>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-blog"
                                onClick={onBlogClick}
                            >
                                <StyledListItemText primary="Blog" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-contact-us"
                                onClick={onContactUsClick}
                            >
                                <StyledListItemText primary="Contact Us" />
                            </ListItem>
                            <ListItem
                                button
                                className="menu-list-item"
                                id="drawer-link-faq"
                                onClick={onFAQClick}
                            >
                                <StyledListItemText primary="FAQ" />
                            </ListItem>
                            <h5>Social</h5>
                            <IconButton
                                className="socials-button"
                                id="drawer-link-facebook"
                                onClick={onFacebookClick}
                            >
                                <StyledFacebook />
                            </IconButton>
                            <IconButton
                                className="socials-button"
                                id="drawer-link-instagram"
                                onClick={onInstagramClick}
                            >
                                <StyledInstagram />
                            </IconButton>
                            <IconButton
                                id="chat-menu-button"
                                aria-label="chat-menu-button"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup
                                onClick={handleChatMenuOpen}
                                className="socials-button"
                            >
                                <ChatIcon />
                            </IconButton>
                        </List>
                    </AccordionDetails>
                </StyledAccordion>

                <NavbarChatMenu anchorEl={chatAnchorEl} setAnchorEl={setChatAnchorEl} />
                <DrawerButtonContainer>
                    <Button
                        variant="contained"
                        className="green-button"
                        id="drawer-link-find-a-space"
                        onClick={onFindASpaceClick}
                    >
                        Find A Space
                    </Button>
                </DrawerButtonContainer>
                <DrawerButtonContainer>
                    <Button
                        variant="contained"
                        className="green-button"
                        id="drawer-link-o-club"
                        onClick={onOclubClick}
                    >
                        O-Club
                    </Button>
                </DrawerButtonContainer>
            </StyledDrawer>
        );
    };

    if (isScrolling) {
        return (
            <div>
                <StyledAppBar position="fixed">
                    <Toolbar>
                        <MenuButton
                            aria-controls={mobileMenuId}
                            aria-haspopup
                            onClick={handleMobileMenuOpen}
                            edge="start"
                            id="scrolling-menu-button"
                        >
                            <StyledMenuIcon />
                        </MenuButton>
                        {renderSrollSearchBar()}
                    </Toolbar>
                </StyledAppBar>
                {renderDrawerMenu()}
            </div>
        );
    }

    return <>{renderShouldNotScroll()}</>;
};

export default MobileNavbar;

MobileNavbar.propTypes = {
    shouldScroll: PropTypes.bool,
    isRoomPage: PropTypes.bool,
    isBookingsPage: PropTypes.bool,
};

const SearchIcon = styled.img`
    width: 12px;
    height: 12px;
    object-fit: contain;
`;

const StyledInputBase = styled(InputBase)`
    font-size: 12px;
    color: ${(props) => props.theme.searchText};
    width: 100%;
`;

const ScrollContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${(props) => props.theme.backgroundColor};
`;

const ScrollContentContainer = styled.div`
    flex-grow: 1;
`;

const StyledMenuIcon = styled(MenuIcon)`
    color: ${(props) => props.theme.white};
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)`
    background: ${(props) => props.theme.backgroundColor};
    box-shadow: none;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const DrawerButtonContainer = styled.div`
    padding: 16px;
`;

const SearchContainer = styled.div`
    position: relative;
    border-radius: 50px;
    background-color: ${(props) => props.theme.black};
    display: flex;
    align-items: center;
    width: 80%;
    height: 40px;
    padding: 6px;
    margin: 10px;
`;

const StyledFacebook = styled(Facebook)`
    color: ${(props) => props.theme.white};
`;

const StyledInstagram = styled(Instagram)`
    color: ${(props) => props.theme.white};
`;

const StyledListItemText = styled(ListItemText)`
    .MuiTypography-body1 {
        font-size: 14px;
        color: ${(props) => props.theme.white};
    }
`;

const MenuButton = styled(IconButton)`
    margin-right: 16px;
`;

const StyledAccordion = styled(Accordion)`
    background: ${(props) => props.theme.backgroundColor};
    border: none;
    box-shadow: none;
    &::before {
        display: none;
    }
`;

const StyledDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        background: ${(props) => props.theme.backgroundColor};
        width: 100%;
    }
`;

const ChatIcon = styled(Chat)`
    color: ${(props) => props.theme.white};
`;

const StyledAutoComplete = styled(Autocomplete)`
    width: 200px;
`;

const StyledExpandMore = styled(ExpandMore)`
    color: white;
`;

const StyledClose = styled(Close)`
    color: white;
`;

const BookNowButton = styled(Button)`
    color: white;
    background-color: #3c5040;
    box-shadow: 0px 7px 34px rgba(49, 114, 97, 0.1021);
    border-radius: 6px;
    font-weight: bold;
    border-color: white;
    border-width: 1.5px;
    border-style: solid;
    width: 136px;
    height: 52px;

    &:hover {
        background-color: var(--homii-grey);
        box-shadow: 0px 7px 34px rgba(49, 114, 97, 0.1021);
    }
`;
