/* eslint-disable no-nested-ternary */
import React from 'react';
import { Container as MUIContainer, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Button, Image, FadeInAnimation } from '../components/common-components';
import MetaData from '../components/common-components/meta-data.component';

const errors = {
    404: {
        title: "Whoops! It looks like you're lost.",
        message: "The requested URL was not found on this server. That's all we know.",
        src: '../../assets/images/404.png',
    },
    500: {
        title: 'We have no idea what happened.',
        message:
            'The server encountered an error and could not complete your request. If the problem persists, please contact support and mention this error message and explain what happened before the error occurred.',
        src: '../../assets/images/500.png',
    },
    503: {
        title: "We'll Be Back Soon",
        message:
            'HOMii is down for scheduled maintenance and expect to back online in a few minutes.',
        src: '../../assets/images/503.png',
    },
};
const possibleErrors = [404, 500, 503];

const Error = ({ statusCode: _statusCode }) => {
    const statusCode = _.includes(possibleErrors, _statusCode) ? _statusCode : 500;
    const router = useRouter();
    const onClickGoHome = () => router.push('/');

    const renderError = () => {
        return (
            <ErrorPageContainer>
                <MUIContainer maxWidth="lg">
                    <Grid container>
                        <Grid item sm={5} xs={12}>
                            <FadeInAnimation>
                                <h6>Error {statusCode}</h6>
                                <h1>{errors[statusCode].title}</h1>
                                <Paragraph>{errors[statusCode].message}</Paragraph>
                                {statusCode !== 503 && (
                                    <Button onClick={onClickGoHome}>Back to Homepage</Button>
                                )}
                            </FadeInAnimation>
                        </Grid>
                        <Grid item sm={7} xs={12}>
                            <FadeInAnimation>
                                <StyledImage
                                    src={errors[statusCode].src}
                                    code={statusCode}
                                    alt={`${statusCode} Error`}
                                    isOldImage
                                />
                            </FadeInAnimation>
                        </Grid>
                    </Grid>
                </MUIContainer>
            </ErrorPageContainer>
        );
    };

    return (
        <>
            <MetaData title={`Error ${statusCode}`} desc="" />
            {statusCode !== 503}
            {renderError()}
        </>
    );
};

Error.getInitialProps = ({ res, err }) => {
    let statusCode;
    if (res) {
        statusCode = res.statusCode;
    } else if (err) {
        statusCode = err.statusCode;
    } else {
        statusCode = 404;
    }

    if (statusCode >= 400 && statusCode < 500) {
        statusCode = 404;
    } else if (statusCode === 503) {
        statusCode = 503;
    } else {
        statusCode = 500;
    }
    return { statusCode };
};

export default Error;

Error.propTypes = {
    statusCode: PropTypes.number,
};

const Paragraph = styled.p`
    margin-bottom: 40px;
    @media (max-width: 700px) {
        margin-bottom: 30px;
    }
`;

const ErrorPageContainer = styled.div`
    position: fixed;
    padding: 0;
    margin: 0;
    margin-top: 60px;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
        margin-top: 130px;
        align-items: flex-start;
    }

    h6 {
        font-size: 16px;
        line-height: 140%;
        color: #B9B9B9;
        margin-bottom: 20px;

        @media (min-width: 600px) {
            margin-bottom: 33px;
        }
    }

    h1 {
        margin-bottom: 18px;

        @media (min-width: 600px) {
            margin-bottom: 24px;
        }

`;

const StyledImage = styled(Image)`
    position: absolute;
    width: 92.5%;
    bottom: ${({ code }) => (code === 404 ? -12 : -33)}%;

    @media (min-height: 700px) {
        bottom: ${({ code }) => (code === 404 ? -2 : -17)}%;
    }

    @media (min-width: 600px) {
        width: 55%;
        left: 40%;

        bottom: ${({ code }) => (code === 404 ? -5 : -25)}%;
    }

    @media (min-width: 1320px) {
        width: 42.5%;
        left: 50%;

        bottom: ${({ code }) => (code === 404 ? -3 : -30)}%;
    }
`;
