import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'payment';

const setTransactionId = new CreateAction(reducerName, 'SET_TRANSACTION_ID');
export const setTransactionIdAction = setTransactionId.action;

const setCheckoutId = new CreateAction(reducerName, 'SET_CHECKOUT_ID');
export const setCheckoutIdAction = setCheckoutId.action;

const setPaymentMethod = new CreateAction(reducerName, 'SET_PAYMENT_METHOD');
export const setPaymentMethodAction = setPaymentMethod.action;

const setInstantEftRedirectUrl = new CreateAction(reducerName, 'SET_INSTANT_EFT_REDIRECT_URL');
export const setInstantEftRedirectUrlAction = setInstantEftRedirectUrl.action;

const setInstantEftParameters = new CreateAction(reducerName, 'SET_INSTANT_EFT_PARAMETERS');
export const setInstantEftParametersAction = setInstantEftParameters.action;

const setInstantEftRedirectMethod = new CreateAction(
    reducerName,
    'SET_INSTANT_EFT__REDIRECT_METHOD',
);
export const setInstantEftRedirectMethodAction = setInstantEftRedirectMethod.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    checkoutId: null,
    transactionId: null,
    paymentMethod: 'card',
    instantEftRedirectUrl: '',
    instantEftParameters: [],
    instantEftRedirectMethod: null,
};

export default function paymentReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setCheckoutId.actionType:
            return { ...state, checkoutId: action.payload };
        case setTransactionId.actionType:
            return { ...state, transactionId: action.payload };
        case setPaymentMethod.actionType:
            return { ...state, paymentMethod: action.payload };
        case setInstantEftRedirectUrl.actionType:
            return { ...state, instantEftRedirectUrl: action.payload };
        case setInstantEftParameters.actionType:
            return { ...state, instantEftParameters: action.payload };
        case setInstantEftRedirectMethod.actionType:
            return { ...state, instantEftRedirectMethod: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
