/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Modal, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useResponsive from '../../helpers/responsive';

const Popup = (props) => {
    const { children, isOpen, onClose } = props;
    const { isMobile, isTablet } = useResponsive();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    const id = open ? 'promotional-popover' : undefined;

    return (
        <Modal
            id={id}
            open={open}
            onClose={handleClose}
            aria-labelledby="promotional-modal"
            aria-describedby="promotional-modal-details"
        >
            <ModalContent $isTablet={isTablet} $isMobile={isMobile}>
                <CloseButton
                    $isTablet={isTablet}
                    $isMobile={isMobile}
                    disableRipple
                    aria-label="close"
                    onClick={handleClose}
                >
                    <CloseButtonIcon />
                </CloseButton>
                {children}
            </ModalContent>
        </Modal>
    );
};

export default Popup;

Popup.propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.any,

    onClose: PropTypes.func,
};

const ModalContent = styled.div`
    position: absolute;
    width: ${({ $isTablet, $isMobile }) => ($isMobile ? 300 : $isTablet ? 654 : 872)}px;
    height: ${({ $isTablet, $isMobile }) => ($isMobile ? 500 : $isTablet ? 400 : 490)}px;
    background-color: #1e252c;
    box-shadow: 0px 55px 52px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    right: ${({ $isTablet }) => ($isTablet ? 15 : 30)}px;
    top: ${({ $isTablet }) => ($isTablet ? 15 : 30)}px;
    z-index: 1;
    background: ${({ $isMobile, theme }) => $isMobile && theme.white};
    background: ${({ $isMobile }) => $isMobile && '0px 4px 20px rgba(0, 0, 0, 0.15)'};
    height: 40px;
    width: 40px;
`;

const CloseButtonIcon = styled(CloseIcon)`
    color: ${(props) => props.theme.subheadingColor};
`;
