import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import styled from 'styled-components';
import { AppBar, Toolbar, IconButton, Link, Button, Backdrop, Grid } from '@material-ui/core';
import { Chat } from '@material-ui/icons';

import { baseColors } from '../../../utils/theme';
import {
    ConnectWithUsMenuComponent,
    FindASpaceMenuComponent,
    WhyHomiiLifeMenuComponent,
} from './desktop-menu-items';

import useDeviceDetect from '../../helpers/device-detect';
import { Image } from '../common-components';
import NavbarChatMenu from './navbar-chat-menu.component';
import MenuImageComponent from './menu-image.component';

export const MENU_ITEMS = Object.freeze({
    find_a_space: 'find-a-space',
    why_homii_life: 'why_homii_life',
    connect_with_us: 'connect-with-us',
});

const DesktopNavbar = ({ isBookingsPage }) => {
    const { isTouchDevice } = useDeviceDetect();
    const [anchorEl, setAnchorEl] = useState(null);
    const [backdropVisible, setBackdropVisible] = useState(false);
    const [currentBackdropData, setCurrentBackdropData] = useState(null);

    const handleChatMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFindSpaceClick = () => {
        Router.push('/find-a-space');
    };

    const handleWhyHomiiClick = () => {
        Router.push('/live/live-your-best-life');
    };

    const handleOclubClick = () => {
        Router.push('/o-club');
    };

    const handleConnectClick = () => {
        Router.push('/connect-with-us');
    };

    const onBookNowClick = () => {
        Router.push('/bookings').then(() => window.scrollTo(0, 0));
    };

    const handleMenuItemHover = (type) => {
        setCurrentBackdropData(type);
        setBackdropVisible(true);
        document.body.style.overflow = 'hidden';
    };

    const handleMenuItemHoverEnd = () => {
        setBackdropVisible(false);
        document.body.style.overflow = 'unset';
    };

    const renderBookNowButton = () => {
        if (isBookingsPage) {
            return null;
        }

        return (
            <BookNowButton
                type="button"
                variant="contained"
                id="book-now-button"
                onClick={onBookNowClick}
            >
                Book Now
            </BookNowButton>
        );
    };

    return (
        <ScrollContainer>
            <ScrollContentContainer>
                <StyledAppBar>
                    <Toolbar>
                        <Link href="/" id="homii-logo-link" aria-label="HOMii Home">
                            <Image
                                src="/assets/images/homii_logo.png"
                                alt="HOMii Logo"
                                width={82.25}
                                height={22}
                            />
                        </Link>

                        <DesktopSection>
                            <StyledButton
                                id="desktop-link-find-a-space"
                                className="nav-link"
                                onMouseEnter={() => handleMenuItemHover(MENU_ITEMS.find_a_space)}
                                onClick={!isTouchDevice && handleFindSpaceClick}
                            >
                                Find a Space
                            </StyledButton>
                            <StyledButton
                                id="desktop-link-why-homii-life"
                                className="nav-link"
                                onMouseEnter={() => handleMenuItemHover(MENU_ITEMS.why_homii_life)}
                                onClick={!isTouchDevice && handleWhyHomiiClick}
                            >
                                Why HOMii Life?
                            </StyledButton>
                            <StyledButton
                                id="desktop-link-connect-with-us"
                                className="nav-link"
                                onMouseEnter={() => handleMenuItemHover(MENU_ITEMS.connect_with_us)}
                                onClick={!isTouchDevice && handleConnectClick}
                            >
                                Connect With Us
                            </StyledButton>
                            <StyledButton
                                id="desktop-link-o-club"
                                className="nav-link"
                                onClick={!isTouchDevice && handleOclubClick}
                            >
                                O-Club
                            </StyledButton>
                        </DesktopSection>
                        <Spacer />

                        <DesktopSection>
                            <ChatButton
                                id="chat-menu-button"
                                aria-label="chat-menu-button"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup
                                onClick={handleChatMenuOpen}
                            >
                                <ChatIcon />
                            </ChatButton>
                        </DesktopSection>
                        {renderBookNowButton()}
                    </Toolbar>

                    <StyledBackdrop open={backdropVisible} onClick={handleMenuItemHoverEnd}>
                        <div className="menu-container" onMouseLeave={handleMenuItemHoverEnd}>
                            <Grid container spacing={3}>
                                {currentBackdropData === MENU_ITEMS.find_a_space && (
                                    <FindASpaceMenuComponent />
                                )}
                                {currentBackdropData === MENU_ITEMS.why_homii_life && (
                                    <WhyHomiiLifeMenuComponent />
                                )}
                                {currentBackdropData === MENU_ITEMS.connect_with_us && (
                                    <ConnectWithUsMenuComponent />
                                )}
                                <Grid container item md={12} lg={6} spacing={3}>
                                    <Grid item lg={4}>
                                        <MenuImageComponent
                                            title="Live"
                                            subtitle="Learn about our app"
                                            imageUrl="/assets/images/live.png"
                                        />
                                    </Grid>
                                    <Grid item lg={4}>
                                        <MenuImageComponent
                                            title="Work"
                                            subtitle="Co-Work with your HOMii's"
                                            imageUrl="/assets/images/work.png"
                                        />
                                    </Grid>
                                    <Grid item lg={4}>
                                        <MenuImageComponent
                                            title="Play"
                                            subtitle="Own your city"
                                            imageUrl="/assets/images/play.png"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </StyledBackdrop>
                </StyledAppBar>
                <NavbarChatMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            </ScrollContentContainer>
        </ScrollContainer>
    );
};

export default DesktopNavbar;

DesktopNavbar.propTypes = {
    isBookingsPage: PropTypes.bool,
};

const ScrollContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: transparent;
`;

const ScrollContentContainer = styled.div`
    max-width: 1600px;
    flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)`
    background-color: transparent;
    position: relative;
    z-index: 10;
    box-shadow: none;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const StyledButton = styled(Button)`
    color: ${baseColors.white};
`;

const DesktopSection = styled.div`
    display: flex;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const StyledBackdrop = styled(Backdrop)`
    margin-top: 74px;
`;

const ChatIcon = styled(Chat)`
    color: ${(props) => props.theme.white};
`;

const ChatButton = styled(IconButton)`
    width: 52px;
    height: 52px;
    background-color: transparent;
    margin-right: 30px;
    border: 1px solid ${(props) => props.theme.white};
`;

const BookNowButton = styled(Button)`
    color: white;
    background-color: #3c5040;
    box-shadow: 0px 7px 34px rgba(49, 114, 97, 0.1021);
    border-radius: 6px;
    font-weight: bold;
    border-color: white;
    border-width: 1.5px;
    border-style: solid;
    width: 136px;
    height: 52px;

    &:hover {
        background-color: var(--homii-grey);
        box-shadow: 0px 7px 34px rgba(49, 114, 97, 0.1021);
    }
`;
