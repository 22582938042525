import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'next/image';

const HOMiiImage = (props) => {
    const {
        style,
        src,
        className,
        greyScale,
        animate,
        isContainerButton,
        isOldImage,
        containerWidth,
        containerHeight,
        alt,
        width,
        height,
        layout,
        objectFit,
        ...rest
    } = props;

    const renderImage = () => {
        if (!isOldImage) {
            return (
                <StyledNextImg
                    {...rest}
                    width={width}
                    height={height}
                    layout={layout}
                    objectFit={objectFit}
                    src={src}
                    alt={alt}
                    style={style}
                    className={className || 'general-image'}
                    $greyscale={greyScale}
                    $animate={animate}
                />
            );
        }
        return (
            <StyledImg
                {...rest}
                width={width}
                height={height}
                src={src}
                alt={alt}
                style={style}
                className={className || 'general-image'}
                $greyscale={greyScale}
                $animate={animate}
            />
        );
    };

    if (animate) {
        if (isContainerButton) {
            return (
                <ImageHoverButton
                    containerWidth={containerWidth}
                    containerHeight={containerHeight}
                    aria-label={alt}
                >
                    {renderImage()}
                </ImageHoverButton>
            );
        }
        return <ImageHover>{renderImage()}</ImageHover>;
    }

    return <>{renderImage()}</>;
};

export default HOMiiImage;

HOMiiImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    greyScale: PropTypes.bool,
    animate: PropTypes.bool,
    isContainerButton: PropTypes.bool,
    containerWidth: PropTypes.string,
    containerHeight: PropTypes.string,
    isOldImage: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    layout: PropTypes.string,
    objectFit: PropTypes.string,
};

const StyledImg = styled.img`
    filter: ${(props) => (props.$greyscale ? 'grayscale(95%)' : 'grayscale(0%)')};
    margin: ${(props) => (props.$animate ? '0 auto' : 'none')};
    transition: ${(props) => props.$animate && 'transform 2s cubic-bezier(.16,1.08,.38,.98)'};

    &:hover {
        transform: ${(props) => (props.$animate ? 'scale(1.1)' : 'none')};
    }
`;

const StyledNextImg = styled(Image)`
    filter: ${(props) => (props.$greyscale ? 'grayscale(95%)' : 'grayscale(0%)')};
    margin: ${(props) => (props.$animate ? '0 auto' : 'none')};
    transition: ${(props) => props.$animate && 'transform 2s cubic-bezier(.16,1.08,.38,.98)'};

    &:hover {
        transform: ${(props) => (props.$animate ? 'scale(1.1)' : 'none')};
    }
`;

const ImageHover = styled.div`
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
`;

const ImageHoverButton = styled.div`
    width: ${(props) => props.containerWidth};
    height: ${(props) => props.containerHeight};
    opacity: ${(props) => props.opacity};
    background: none;
    border: none;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;

    &:focus {
        outline: 0;
    }
    &:hover {
        cursor: pointer;
    }
`;
