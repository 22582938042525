import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

import useResponsive from '../../helpers/responsive';

const RoomsContentLoader = ({ isGrid }) => {
    const { isMobile, isTablet } = useResponsive();
    let containerWidth = 1500;
    let containerHeight = 400;
    let boxWidth = 400;
    let offset = 0;

    if (isTablet) {
        containerWidth = 1200;
        containerHeight = 380;
        offset = 50;
        boxWidth = boxWidth - offset;
    }
    if (isMobile && isGrid) {
        containerWidth = 300;
        containerHeight = 1800;
        offset = 260;
    }

    const renderDefaultLoader = () => {
        if (isGrid) {
            return null;
        }

        return (
            <ContentLoader
                width={containerWidth}
                height={containerHeight}
                viewBox={`0 0 ${containerWidth} ${containerHeight}`}
                backgroundColor="#1E252C"
                foregroundColor="#181D1F"
                animate
            >
                <rect x="12" y="9" rx="2" ry="2" width="200" height="10" />
                <rect x="12" y="30" rx="2" ry="2" width="150" height="11" />

                <rect x="12" y="58" rx="2" ry="2" width={boxWidth} height="260" />
                <rect x="12" y="340" rx="2" ry="2" width="150" height="10" />
                <rect x="12" y="360" rx="2" ry="2" width="250" height="10" />
                <rect x="12" y="380" rx="2" ry="2" width="250" height="10" />

                <rect x={462 - offset} y="57" rx="2" ry="2" width={boxWidth} height="260" />
                <rect x={462 - offset} y="340" rx="2" ry="2" width="150" height="10" />
                <rect x={462 - offset} y="360" rx="2" ry="2" width="250" height="10" />
                <rect x={462 - offset} y="380" rx="2" ry="2" width="250" height="10" />

                <rect x={912 - 2 * offset} y="56" rx="2" ry="2" width={boxWidth} height="260" />
                <rect x={912 - 2 * offset} y="340" rx="2" ry="2" width="150" height="10" />
                <rect x={912 - 2 * offset} y="360" rx="2" ry="2" width="250" height="10" />
                <rect x={912 - 2 * offset} y="380" rx="2" ry="2" width="250" height="10" />
            </ContentLoader>
        );
    };

    const renderGridLoader = () => {
        if (!isGrid) {
            return null;
        }
        return (
            <ContentLoader
                width={containerWidth}
                height={containerHeight}
                viewBox={`0 0 ${containerWidth} ${containerHeight}`}
            >
                <rect x="12" y="9" rx="2" ry="2" width="200" height="10" />
                <rect x="12" y="30" rx="2" ry="2" width="150" height="11" />

                <rect x="12" y="58" rx="2" ry="2" width={boxWidth} height="260" />
                <rect x="12" y="340" rx="2" ry="2" width="150" height="10" />
                <rect x="12" y="360" rx="2" ry="2" width="250" height="10" />
                <rect x="12" y="380" rx="2" ry="2" width="250" height="10" />

                <rect x="12" y={78 + offset} rx="2" ry="2" width={boxWidth} height="260" />
                <rect x="12" y={360 + offset} ry="2" width="150" height="10" />
                <rect x="12" y={380 + offset} ry="2" width="250" height="10" />
                <rect x="12" y={400 + offset} ry="2" width="250" height="10" />

                <rect x="12" y={98 + 2 * offset} rx="2" ry="2" width={boxWidth} height="260" />
                <rect x="12" y={380 + 2 * offset} ry="2" width="150" height="10" />
                <rect x="12" y={400 + 2 * offset} ry="2" width="250" height="10" />
                <rect x="12" y={420 + 2 * offset} ry="2" width="250" height="10" />

                <rect x="12" y={118 + 3 * offset} rx="2" ry="2" width={boxWidth} height="260" />
                <rect x="12" y={400 + 3 * offset} ry="2" width="150" height="10" />
                <rect x="12" y={420 + 3 * offset} ry="2" width="250" height="10" />
                <rect x="12" y={440 + 3 * offset} ry="2" width="250" height="10" />

                <rect x="12" y={138 + 4 * offset} rx="2" ry="2" width={boxWidth} height="260" />
                <rect x="12" y={420 + 4 * offset} ry="2" width="150" height="10" />
                <rect x="12" y={440 + 4 * offset} ry="2" width="250" height="10" />
                <rect x="12" y={460 + 4 * offset} ry="2" width="250" height="10" />

                <rect x="12" y={158 + 5 * offset} rx="2" ry="2" width={boxWidth} height="260" />
                <rect x="12" y={440 + 5 * offset} ry="2" width="150" height="10" />
                <rect x="12" y={460 + 5 * offset} ry="2" width="250" height="10" />
                <rect x="12" y={480 + 5 * offset} ry="2" width="250" height="10" />
            </ContentLoader>
        );
    };

    return (
        <StyledContainer $isGrid={isGrid} $isMobile={isMobile} $isTablet={isTablet} maxWidth="lg">
            {renderGridLoader()}
            {renderDefaultLoader()}
        </StyledContainer>
    );
};

export default RoomsContentLoader;

const StyledContainer = styled(Container)`
    display: ${({ $isGrid }) => $isGrid && 'flex'};
    justify-content: center;
    padding-top: ${({ $isMobile, $isTablet }) => ($isMobile ? 40 : $isTablet ? 90 : 100)}px;
    padding-bottom: ${({ $isMobile, $isTablet }) => ($isMobile ? 30 : $isTablet ? 100 : 130)}px;
`;

RoomsContentLoader.propTypes = {
    isGrid: PropTypes.bool,
};
