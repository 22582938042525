import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ReactGA from 'react-ga';
import getConfig from 'next/config';

const SeverityLevel = {
    ERROR: 'error',
    INFORMATION: 'information',
};
const { publicRuntimeConfig } = getConfig();
const prod = process.env.NODE_ENV === 'production';
const IsBrowser = typeof window !== 'undefined';

let appInsights = null;

async function initAppInsights() {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: publicRuntimeConfig.HOMII_APP_INSIGHTS_INSTRUMENTATION_KEY,
            isEnabled: prod && publicRuntimeConfig.HOMII_FEATURE_APP_INSIGHTS_EVENTS,
        },
    });
    appInsights.loadAppInsights();
    window.appInsights = appInsights;
}

async function logCustomEvent(eventName, options) {
    if (IsBrowser && prod) {
        // Log App Insights Event:
        await initAppInsights();
        if (appInsights) {
            window.appInsights.trackEvent({
                name: eventName,
                data: options,
                currentUrl: window.location.href,
            });
        }
        // Log Google Analytics Event:
        ReactGA.event({
            category: eventName,
            action: options.description,
        });
    }
}

async function trackException(errorMessage) {
    if (IsBrowser && prod) {
        await initAppInsights();
        if (appInsights) {
            window.appInsights.trackException({
                error: new Error(errorMessage),
                severityLevel: SeverityLevel.ERROR,
            });
        }
    }
}

async function trackTrace(traceMessage) {
    if (IsBrowser && prod) {
        await initAppInsights();
        if (appInsights) {
            window.appInsights.trackTrace({
                message: traceMessage,
                severityLevel: SeverityLevel.Information,
            });
        }
    }
}

export default {
    logCustomEvent,
    trackException,
    trackTrace,
};
