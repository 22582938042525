import React from 'react';
import Slider from '@material-ui/core/Slider';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
export default function RangeSlider(props) {
    const { min, max, defaultValues, onChange, width } = props;

    const handleChange = (event, newValues) => {
        if (onChange) {
            onChange(newValues);
        }
    };

    const valuetext = (val) => {
        return `R${val}`;
    };

    return (
        <SliderContainer width={width}>
            <StyledSlider
                value={_.map(defaultValues, Number)}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="price-range-slider"
                getAriaValueText={valuetext}
                min={min}
                max={max}
            />
        </SliderContainer>
    );
}

RangeSlider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValues: PropTypes.array,
    width: PropTypes.number,

    onChange: PropTypes.func,
};

const SliderContainer = styled.div`
    .MuiSlider-root {
        width: ${(props) => (props.width ? `${props.width}px` : '100%')};
        margin-top: 20px;
    }
`;

const StyledSlider = styled(Slider)`
    .MuiSlider-rail {
        height: 4px;
        border-radius: 4px;
    }

    .MuiSlider-thumb {
        width: 16px;
        height: 16px;
        margin-top: -6px;
        color: ${(props) => props.theme.primaryLight};
        &:hover {
            border: 2px solid ${(props) => props.theme.priceSliderDotBorder};
        }
    }

    .MuiSlider-valueLabel {
        display: none;
    }

    .MuiSlider-track {
        background-color: ${(props) => props.theme.primaryLight};
    }
`;
