import React from 'react';
import { useRouter } from 'next/router';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../../../utils/theme';

const useStyles = makeStyles({
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
    },
});

const PAGINATION_SHOW_COUNT = 3;

export default function UsePagination(props) {
    const { count } = props;
    const pageNumber = parseInt(props.page, 10);
    const router = useRouter();
    const { query } = router;
    const classes = useStyles();
    const { items } = usePagination({
        count,
        page: pageNumber,
    });

    const changeToPageNumber = (pageTo) => {
        let newQuery;
        if (pageTo > 1) {
            newQuery = { ...query, page: pageTo };
        } else {
            newQuery = { ...query };
            delete newQuery.page;
        }
        return () => {
            router.push(
                {
                    pathname: '/bookings',
                    query: newQuery,
                },
                undefined,
                { shallow: true },
            );
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        };
    };

    const arrowNavigation = (type) => {
        if (type === 'previous' && pageNumber > 1) {
            return changeToPageNumber(pageNumber - 1);
        }
        if (type === 'next' && pageNumber < count) {
            return changeToPageNumber(pageNumber + 1);
        }
        return undefined;
    };

    return (
        <nav id="pagination-nav">
            <ul className={classes.ul}>
                {items.map(({ page, type, selected, disabled, ...item }, index) => {
                    let children;
                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = '…';
                    } else if (type === 'previous' || type === 'next') {
                        children = (
                            <StyledButton
                                type="button"
                                {...item}
                                onClick={arrowNavigation(type)}
                                active={!disabled}
                            >
                                {type === 'previous' ? 'Prev' : 'Next'}
                            </StyledButton>
                        );
                    }

                    if (pageNumber <= PAGINATION_SHOW_COUNT) {
                        if (type === 'page' && index <= PAGINATION_SHOW_COUNT) {
                            children = (
                                <StyledPageButton
                                    type="button"
                                    selected={selected}
                                    {...item}
                                    onClick={changeToPageNumber(page)}
                                >
                                    {page}
                                </StyledPageButton>
                            );
                        } else if (type === 'page' && index > PAGINATION_SHOW_COUNT) {
                            return null;
                        }
                    } else if (index > pageNumber - PAGINATION_SHOW_COUNT && index <= pageNumber) {
                        if (type === 'page') {
                            children = (
                                <StyledPageButton
                                    type="button"
                                    selected={selected}
                                    {...item}
                                    onClick={changeToPageNumber(page)}
                                >
                                    {page}
                                </StyledPageButton>
                            );
                        } else if (type === 'page' && index > PAGINATION_SHOW_COUNT) {
                            return null;
                        }
                    }

                    return (
                        <li id={`pagination-nav-item-${index}`} key={index}>
                            {children}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}

const StyledPageButton = styled.button`
    font-size: 16px;
    width: 62px;
    height: 62px;
    border-radius: 62px;
    border: none;
    outline: none;
    color: #aaaaaa;
    background-color: ${(props) => (props.selected ? props.theme.paragraphDark : 'transparent')};
    cursor: pointer;
`;

const StyledButton = styled.button`
    background-color: ${theme.transparent};
    outline: none;
    border: none;
    padding: 32px;
    font-size: 16px;
    font-weight: bold;
    color: ${({ active, theme }) => (active ? theme.white : theme.backgroundColor)};
    cursor: ${({ active }) => (active ? 'pointer' : 'default')};
`;

UsePagination.propTypes = {
    count: PropTypes.number,
    page: PropTypes.number,
};
