import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'geolocation';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setCoordinates = new CreateAction(reducerName, 'SET_COORDINATES');
export const setCoordinatesAction = setCoordinates.action;

const setGeoError = new CreateAction(reducerName, 'SET_GEO_ERROR');
export const setGeoErrorAction = setGeoError.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    coordinates: { latitude: null, longitude: null, isBrowser: null },
    geoError: null,
};

export default function geolocationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setCoordinates.actionType:
            return { ...state, coordinates: action.payload };

        case setGeoError.actionType:
            return { ...state, geoError: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
