import networkService from '../network-service/network.service';
import agentUrls from './agent-service-urls';

function getAgentById(id) {
    const url = agentUrls.getAgentByIdUrl(id);
    return networkService.makeGet(url);
}

function getAgentByPortfolioId(portfolioId) {
    const url = agentUrls.getAgentByPortfolioIdUrl(portfolioId);
    return networkService.makeGet(url);
}

function submitAgentInquiry(agentId, roomTypeId, firstName, lastName, phoneNumber, email, inquiry) {
    const url = agentUrls.inquiry();
    const data = {
        agentId,
        roomTypeId,
        firstName,
        lastName,
        phoneNumber,
        email,
        inquiry,
    };
    return networkService.makePost(url, data);
}

export default {
    getAgentById,
    getAgentByPortfolioId,
    submitAgentInquiry,
};
