import React from 'react';
import PropTypes from 'prop-types';

import useResponsive from '../../helpers/responsive';
import DesktopNavbar from './desktop-navbar.component';
import MobileNavbar from './mobile-navbar.component';

const Navbar = ({ shouldScroll, isRoomPage, isBookingsPage }) => {
    const { isMobile, isSmallTablet } = useResponsive();

    if (isMobile || isSmallTablet) {
        return (
            <MobileNavbar
                shouldScroll={shouldScroll}
                isBookingsPage={isBookingsPage}
                isRoomPage={isRoomPage}
            />
        );
    }

    return <DesktopNavbar isBookingsPage={isBookingsPage} />;
};

export default Navbar;

Navbar.propTypes = {
    shouldScroll: PropTypes.bool,
    isRoomPage: PropTypes.bool,
    isBookingsPage: PropTypes.bool,
};
