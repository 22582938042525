const cityBaseUrl = '/City';
const buildingBaseUrl = '/Building';
const roomTypeBaseUrl = '/RoomType';
const portfolioBaseUrl = '/Portfolio';
const agentBaseUrl = '/Agent';

export default {
    getCityDocumentUrl: (cityId, documentId) =>
        `${cityBaseUrl}/document?CityId=${cityId}&DocumentId=${documentId}`,
    getCityDocumentThumbnailUrl: (thumbnailScale, thumbnailQuality, cityId, documentId) =>
        `${cityBaseUrl}/document/thumbnail?ThumbnailScale=${thumbnailScale}&ThumbnailQuality=${thumbnailQuality}&CityId=${cityId}&DocumentId=${documentId}`,
    getBuildingDocumentUrl: (buildingId, documentId) =>
        `${buildingBaseUrl}/document?BuildingId=${buildingId}&DocumentId=${documentId}`,
    getBuildingDocumentThumbnailUrl: (thumbnailScale, thumbnailQuality, buildingId, documentId) =>
        `${buildingBaseUrl}/document/thumbnail?ThumbnailScale=${thumbnailScale}&ThumbnailQuality=${thumbnailQuality}&BuildingId=${buildingId}&DocumentId=${documentId}`,
    getRoomTypeDocumentUrl: (roomTypeId, documentId) =>
        `${roomTypeBaseUrl}/document?RoomTypeId=${roomTypeId}&DocumentId=${documentId}`,
    getRoomTypeDocumentThumbnailUrl: (thumbnailScale, thumbnailQuality, roomTypeId, documentId) =>
        `${roomTypeBaseUrl}/document/thumbnail?ThumbnailScale=${thumbnailScale}&ThumbnailQuality=${thumbnailQuality}&RoomTypeId=${roomTypeId}&DocumentId=${documentId}`,
    getPortfolioDocumentUrl: (portfolioId, documentId) =>
        `${portfolioBaseUrl}/document?PortfolioId=${portfolioId}&DocumentId=${documentId}`,
    getPortfolioDocumentThumbnailUrl: (thumbnailScale, thumbnailQuality, portfolioId, documentId) =>
        `${portfolioBaseUrl}/document/thumbnail?ThumbnailScale=${thumbnailScale}&ThumbnailQuality=${thumbnailQuality}&PortfolioId=${portfolioId}&DocumentId=${documentId}`,
    getAgentDocumentUrl: (agentId, documentId) =>
        `${agentBaseUrl}/document?AgentId=${agentId}&DocumentId=${documentId}`,
    getAgentDocumentThumbnailUrl: (thumbnailScale, thumbnailQuality, agentId, documentId) =>
        `${agentBaseUrl}/document/thumbnail?ThumbnailScale=${thumbnailScale}&ThumbnailQuality=${thumbnailQuality}&AgentId=${agentId}&DocumentId=${documentId}`,
};
