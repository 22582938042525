import { createMuiTheme } from '@material-ui/core/styles';
import { createGlobalStyle } from 'styled-components';

// These are common colours used in app, but not homii specific colours.
export const baseColors = {
    transparent: 'transparent',
    white: '#fff',
    black: '#000',
    blueCharcoal: '#1E252C',
    whiteout: '#FBFBFB',
    lightGrey: '#F8F8F8',
    blackPearl: '#151A1F',
    veryLightGrey: '#D2D3D4',
    blueZodiac: '#434856',
    darkText: '#434d68',
    darkIcon: '#2D354C',
    whiteSmoke: '#F1F1F1',
    darkGrey: '#A2A2A2',
    anotherLightGrey: '#F4F4F4',
    middleGrey: '#EDEFF2',
};

// Styled components theme, this is our primary theme
export const theme = {
    darkText: '#434d68',
    primaryLight: '#BFC849',
    primaryDark: '#97b200',
    white: baseColors.white,
    black: baseColors.black,
    whiteout: baseColors.whiteout,
    darkerWhiteout: baseColors.anotherLightGrey,
    transparent: 'transparent',
    searchButtonText: baseColors.white,
    searchDivider: baseColors.lightGrey,
    shadow: baseColors.lightGrey,
    darkIconBackground: baseColors.blackPearl,
    greyIconBackground: 'rgba(67, 77, 104, 0.05)',
    lightIconBackground: 'rgba(191, 200, 73, 0.1)',
    lightButtonBackground: '#F9FAED',
    sloganIconBackground: 'rgba(191, 200, 73, 0.1)',
    paragraphLight: baseColors.white,
    paragraphMedium: baseColors.darkGrey,
    paragraphDark: baseColors.blueCharcoal,
    sectionBackgroundDark: baseColors.blueCharcoal,
    captionText: baseColors.blueCharcoal,
    placeholderSearchText: baseColors.veryLightGrey,
    searchText: baseColors.white,
    searchTextHeading: baseColors.darkText,
    calendarBlack: baseColors.blackPearl,
    calendarGrey: baseColors.veryLightGrey,
    calendarNav: baseColors.blueZodiac,
    dropdownHover: baseColors.whiteSmoke,
    dropdownSelect: baseColors.veryLightGrey,
    checkboxSecondary: baseColors.veryLightGrey,
    carouselSubheading: baseColors.darkGrey,
    carouselButtonBorder: baseColors.veryLightGrey,
    loadingPrimaryButton: '#8F9D24',
    loadingSecondaryButton: 'rgba(0,0,0,0.07)',
    whyHomiiLifeHeroBackground: 'rgba(243, 246, 246, 0.5)',
    priceSliderDotBorder: '#EBEFBB',
    heroBackground: baseColors.middleGrey,
    backgroundColor: '#181D1F',
    heroBackgroundColor: '#',
    subheadingColor: '#B9B9B9',
    searchBarHeadings: '#AAAAAA',
    featuredSpacesBackground: '#243239',
};

// Create a theme for material UI instance, primarily used to overwrite MUI defaults
export const themeMui = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                color: 'white',
            },
        },
        MuiInputLabel: {
            root: {
                color: 'white',
            },
        },
    },
    typography: {
        fontFamily: 'Nunito Sans, sans-serif',
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: '#434856',
        },
        secondary: {
            main: '#BFC849',
        },
    },
    textField: {
        root: {
            backgroundColor: 'white',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1320,
            xl: 1920,
        },
    },
});

// Global style for styled components, e.g. inject global font
export const GlobalStyles = createGlobalStyle`
h1 {
    font-family: 'Josefin Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 76.29px;
    line-height: 92.5%;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
}
h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 61px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
}
h3 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 39px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
}
h4 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 31.25px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
}
h5 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
}
h6 {
    display: block;
    text-align: left;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
}
p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.04em;
    color: ${theme.white};
    margin-top: 4px;
    margin-bottom: 4px;
}
caption {
    display: block;
    text-align: left;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
}
a {
    font-size: 20px;
}
body {
  font-family: 'Nunito Sans', sans-serif;
  margin: 0;
  background-color: ${theme.backgroundColor};
}


@media only screen and (max-width: 1100px) {
  h1 {
    font-size: 47.8px;
  }
  h2 {
    font-size: 39.81px;
  }
  h3 {
    font-size: 27.65px;
  }
  h4 {
    font-size: 23.04px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 13.3px;
  }
  p {
    font-size: 16px;
  }
  caption {
    font-size: 13.3px;
  }
  a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
    h1 {
      font-size: 41.8px;
    }
    h2 {
      font-size: 29px;
    }
    h3 {
      font-size: 20.16px;
    }
    h4 {
      font-size: 16.8px;
    }
    h5 {
      font-size: 14px;
    }
    h6 {
        font-size: 11.7px;
    }
    p {
      font-size: 14px;
    }
    caption {
        font-size: 11.7px;
    }
    a {
        font-size: 14px;
    }
  }
`;
